
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const userAuthApi = createApi({
  reducerPath: 'userAuthApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_URL }),
  endpoints: (builder) => ({
   registerUser:builder.mutation({
    query:(user)=>
    {
        return{
            url:'user/create/',
            method: 'POST',
            body:user, 
            headers:{
                'Content-type' : 'application/json',
            }
        }
    }
   }),
   loginUser:builder.mutation({
    query:(user)=>
    {
        return{
            url:'login/',
            method: 'POST',
            body:user, 
            headers:{
                'Content-type' : 'application/json',
            }
        }
    }
   })
  }),
})
export const { useRegisterUserMutation,useLoginUserMutation } = userAuthApi