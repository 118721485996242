import React, { useEffect, useState } from 'react'
import "../styles/teamdetail.scss"
import { RxCross1 } from 'react-icons/rx';
import TextField from '@mui/material/TextField';
import { MdEdit } from "react-icons/md";
import axios from 'axios';
const TeamDetailView = (props) => {
  const [data,setdata] = useState([])
  const url = process.env.REACT_APP_URL
  const fetchdata=()=>
  {
    axios.get(`${url}tracker/${props.id}`)
    .then( res => setdata(res.data))
    .catch()
  }
  useEffect(()=>{
    fetchdata()
  },[])
  const formatDatetime = (datetimeString) => {
    const datetime = new Date(datetimeString); // Convert the datetime string to a Date object
    const day = datetime.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
    const month = (datetime.getMonth() + 1).toString().padStart(2, '0'); // Get the month (Note: month is zero-based) and pad with leading zero if needed
    const year = datetime.getFullYear(); // Get the year
  
    return `${day}/${month}/${year}`; // Return the formatted date string
  };
  
  return (
    <>
  
      <div className="teamdetail-parent glass">
      <div className="teamdetail-container">
        <p><RxCross1 onClick={props.close} /></p>
        <p><MdEdit onClick={props.reedit} id={props.id}/></p>
          <form action="">
            <TextField
              id="outlined-approved"
              label="Approved - Strategy / Plan (Y/N)"
              defaultValue="yes"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.approved_strategy}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-clients"
              label="Client"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.client_name}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-work-type"
              label="Work Type"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.work_type}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-deliverable-type"
              label="Deliverable Type"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.deliverable_type}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-completion-date"
              label="Projected Completion Date (DD/MM/YYYY)"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.projected_completion_date}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-deliverable-description"
              label="Deliverable Description"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              multiline
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.deliverable_description}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-deliverable-brief"
              label="Deliverable Links"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              multiline
              value={data.deliverable_brief}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-platform"
              label="Platform"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.platform}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-campaign-type"
              label="Type of Campaign"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.type_of_campaign}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-internal-client"
              label="Internal/Client Deliverables"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.Client_deliverables}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-deliverable-duration"
              label="Deliverable Duration (If Applicable)"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.Deliverable_duration}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-entry-created-by"
              label="Entry Created By"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.Entry_by}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-entry-created-on"
              label="Entry Created On (DD/MM/YYYY)"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={formatDatetime(data.Entry_created_on)}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-owner"
              label="Owner"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.sheet_owner_name && data.sheet_owner_name}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-start-date"
              label="Start Date (DD/MM/YYYY)"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.start_date}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-completion-date"
              label="Completion Date (DD/MM/YYYY)"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.completion_date}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-final-approver-name"
              label="Final Approver Name"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.approver_name}
              InputLabelProps={{ shrink: true }} 
            />

            <TextField
              id="outlined-final-approval-made"
              label="Final Approval Made (Y/N)0"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.approval_made}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="outlined-status"
              label="Status"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.status_name && data.status_name}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="outlined-overall-status"
              label="Overall Status"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.overall_status}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="outlined-teams-log"
              label="Team Name"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.related_team_name && data.related_team_name}
              InputLabelProps={{ shrink: true }} 
              />
            <TextField
              multiline
              id="outlined-comments-log"
              label="Comments"
              defaultValue=""
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              fullWidth={false}
              className='text-input'
              margin="normal"
              value={data.comments_log}
              InputLabelProps={{ shrink: true }} 
            />
          </form>
        
      </div>
    </div>
  
    
    </>
  )
}

export default TeamDetailView 