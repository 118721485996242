import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "../styles/membersheet.scss"
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
// skeleton
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import DeleteConfirm from '../modals/DeleteConfirm';
import axios from 'axios';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const MemberSheet = () => {
     // 
    // 
    const [loading, setloading] = useState(false)
    const [detail, setdetail] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [data,setdata] =  useState([])
    const url = process.env.REACT_APP_URL
    const navigate = useNavigate()
    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'employee_id', headerName: 'Employee ID', width: 150 },
        { field: 'employee_name', headerName: 'Employee Name', width: 250 },
        { field: 'employee_designation', headerName: 'Employee Designation', width: 200 },
        // {
        //     field: 'employee_team',
        //     headerName: 'Employee Team',
        //     width: 250,
        // },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='handle-buttons'>
                    <button onClick={() => handleView(params.row.id)} className='action-btn'><FaEye /></button>
                    <button style={{display:"none"}} className='action-btn'><MdEdit /></button>
                    <button onClick={() => {setShowDeleteConfirmation(true);setDeletedId(params.row.id)}} className='action-btn'><FaTrash /></button>
                </div>
            ),
        },
    ];
    const handleView = (id) => {
        navigate(`/member/${id}`)
    };

    const handleConfirmDelete = () => {
        axios.delete(`${url}employee/${deletedId}`)
        .then(res => {toast.success('Data Removed', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Flip,
        });;setShowDeleteConfirmation(false);fetchEmployee();})
        .catch()
        
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const rows = data && data.map(item => ({
        id: item.id,
        employee_id: item.employee_id,
        employee_name: item.employee_name,
        employee_designation: item.employee_designation,
        // employee_team: item.teams ? item.teams : "Not in Team"
    }));
    
       
    ;
    const fetchEmployee=()=>{
        axios.get(`${url}employee/`)
        .then(res =>{setloading(true);setdata(res.data)})
        .catch()
    }
    useEffect(()=>{
        fetchEmployee();
    },[])
    return (
        <>        <div className='member-sheet'>
            {loading ? <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                disableRowSelectionOnClick={true}
                hideFooterSelectedRowCount={true}
            /> : <>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                </Stack>
            </>}
        </div>
            {showDeleteConfirmation && <DeleteConfirm onDelete={handleConfirmDelete} onClose={handleCloseDeleteConfirmation} />}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="Flip"
            />
        </>

    )
}

export default MemberSheet