import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "../styles/teamsheet.scss";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
// skeleton
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import TeamDetailView from '../modals/TeamDetailView';
import EditTeamSheet from '../modals/EditTeamSheet';
import DeleteConfirm from '../modals/DeleteConfirm';

const TeamSheet = () => {
    const [loading, setloading] = useState(false)
    const [detail, setdetail] = useState(false)
    const [edit, setedit] = useState(false)
    const [deletedata, setdeletedata] = useState(false)
    setTimeout(() => {
        setloading(true)
    }, 100);
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Owner', headerName: 'Owner', width: 130 },
        { field: 'Entry_by', headerName: 'Entry Created By', width: 180 },
        { field: 'lastName', headerName: 'Start Date', width: 130 },
        {
            field: 'age',
            headerName: 'End Date',
            width: 130,
        },
        {
            field: 'enddate',
            headerName: 'Status',
            width: 130,
        },
        {
            field: 'feedback',
            headerName: 'Feedback',
            sortable: false,
            valueGetter: (params) => params.row.feedback,
            width: 200,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='handle-buttons'>
                    <button onClick={() => handleView(params.row.id)} className='action-btn'><FaEye /></button>
                    <button onClick={() => handleEdit(params.row.id)} className='action-btn'><MdEdit /></button>
                    <button onClick={() => setdeletedata(true)} className='action-btn'><FaTrash /></button>
                </div>
            ),
        },
    ];
    const handleView = (id) => {
        // Implement your edit logic here
        setdetail(true)
    };
    const handleEdit = (id) => {
        // Implement your edit logic here
        setedit(true)
    };

    const handleDelete = (id) => {

    };
    const close=()=>
    {
        setdetail(false)
    }
    const closeedit=()=>
    {
        setedit(false)
    }
    const reedit=()=>
    {
        setedit(true)
        setdetail(false)
    }
    const closedelete=()=>
    {
        
        setdeletedata(false)
    }
    const rows = [
        { id: 1, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 2, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 3, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 4, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 5, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 6, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed by sunil shelke and removed by zaigham  @ diwise.india" },
        { id: 7, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 8, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 9, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
        { id: 10, lastName: 'Snow', Owner: 'Jon', age: 35, enddate: '12/02/2002', feedback: "completed" },
    ];

    return (
        <>
            <div className='team-sheet'>
                {loading ? <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 15 },
                        },
                    }}
                    disableRowSelectionOnClick={true}
                    hideFooterSelectedRowCount={true}
                /> : <>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    </Stack>
                </>}
            </div>
            {detail && <TeamDetailView close={close} reedit={reedit}/>}
            {edit && <EditTeamSheet close={closeedit}/>}
            {deletedata && <DeleteConfirm onDelete={handleDelete} onClose={closedelete} />}
        </>
    )
}

export default TeamSheet