import React from 'react'
import "../styles/layout.scss"
import Sidebar from '../components/Sidebar'
const Layout = ({ children }) => {
    return (
        <div className="layout-parent">
            <div className="layout-left">
                <Sidebar />
            </div>
            <div className="layout-right">
                {children}
            </div>
        </div>
    )
}

export default Layout