import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { FaTrash } from "react-icons/fa";
import "../styles/clientsheet.scss"
import { Button } from '@mui/material';
import ClientModel from './ClientModel';
import DeleteConfirm from '../modals/DeleteConfirm';
const Clients = () => {
    const [data, setdata] = useState([])
    const [toggle, settoggle] = useState(false)
    const [deletedid,setdeletedid] = useState()
    const [deletedata,setdeletedata] = useState(false)
    const url = process.env.REACT_APP_URL
    const fetchdata = () => {
        axios.get(`${url}clients/`)
            .then(res => setdata(res.data))
            .catch()
    }
    const columns = [
        // { field: 'id', headerName: 'Id', width: 150 },
        { field: 'client_name', headerName: 'Client Name', width: 250 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='handle-buttons3'>
                    <button className='action-btn3' onClick={()=>{setdeletedata(true);setdeletedid(params.row.id)}}><FaTrash /></button>
                </div>
            ),
        },
    ]
    const rows = data && data.map((item,index) => ({
        id: item.id,
        client_name: item.client_name,

    }));
    useEffect(() => {
        fetchdata();
    }, [])
    const CustomToolbar = () => {
        return (
            <div className="button-custom" style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "0px 10px" }}>
                <Button style={{ width: "fit-content", margin: "10px 10px 0px 0px" }} variant="contained" color="primary" onClick={()=>settoggle(true)}>
                    Add New
                </Button>
            </div>
        );
    };

    const handleDelete = () => {
        axios.delete(`${url}clients/${deletedid}`)
        .then( res=> {fetchdata()})
        .catch()
    };
    const closedelete = () => {
        
        setdeletedata(false)
    }
    return (
        <>
            <div className='user-sheet'>
                 
                <DataGrid
                    className='datagrid'
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    disableRowSelectionOnClick={true}
                    hideFooterSelectedRowCount={true}
                    slots={{
                        toolbar: () => (
                            <React.Fragment>
                                <CustomToolbar />
                            </React.Fragment>
                        ),
                    }}
                />
            </div>
            {toggle && <ClientModel close={()=>settoggle(false)} refresh={()=>fetchdata()}/>}
            {deletedata && <DeleteConfirm onDelete={handleDelete} onClose={closedelete} />}
        </>

    )
}

export default Clients