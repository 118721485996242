import './App.scss';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './authentication/Login';
import Layout from './layout/Layout';
import Teams from './pages/Teams';
import TeamSheet from './pages/TeamSheet';
import MemberSheet from './pages/MemberSheet';
import AddMember from './modals/AddMember';
import AllEnteries from './pages/AllEnteries';
import NewEnteries from './pages/NewEnteries';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import Test from './pages/Test';
import SheetByTeam from './pages/SheetByTeam';
import ManageUsers from './pages/ManageUsers';
import ChangePassword from './modals/ChangePassword';
import CreateUser from './modals/CreateUser';
import axios from 'axios';
import LoginLogs from './pages/LoginLogs';
import UpdateLogs from './pages/UpdateLogs';
import TeamMembers from './modals/TeamMembers';
import OldView from './pages/OldView';
import ManagerTeam from './manager/ManagerTeam';
import PersonalSheet from './manager/PersonalSheet';
import Clients from './legends/Clients';
import DeliverableType from './legends/DeliverableType';
import Approvers from './legends/Approvers';
import WorkType from './legends/WorkType';
import TeamManageView from './manager/TeamManageView';
import ManagerOldView from './manager/ManagerOldView';
import Updates from './notice/Updates';
function App() {
  const [role, setRole] = useState('');
  const [data, setData] = useState('');
  const { access_token } = useSelector(state => state.auth);
  const username = localStorage.getItem("user_name");
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    if (access_token) {
      axios.get(`${url}user-profile/${username}/`)
        .then(res => {
          setRole(res.data.role);
          setData(res.data)
        })
        .catch(err => {
          ;
        });
    }
  }, [username]);

  return (
    <div className="App">
      <BrowserRouter basename="">
        {access_token
          ?
          <>
            {
              role === "admin" &&
              <Layout>
                <Routes>
                  <Route path="/login" element={!access_token ? <Login /> : <Navigate to="/" />} />
                  <Route path='/' element={<Teams />} />
                  <Route path='/updates' element={<Updates />} />
                  <Route path='/clients' element={<Clients />} />
                  <Route path='/deliverable-type' element={<DeliverableType />} />
                  <Route path='/approvers' element={<Approvers />} />
                  <Route path='/worktype' element={<WorkType />} />
                  <Route path='/test' element={<Test />} />
                  <Route path='/members' element={<MemberSheet />} />
                  <Route path='/addmembers' element={<AddMember />} />
                  <Route path='/allenteries' element={<AllEnteries />} />
                  <Route path='/newenteries' element={<NewEnteries />} />
                  <Route path='/teamsheet' element={<TeamSheet />} />
                  <Route path='/manage' element={<ManageUsers />} />
                  <Route path='/teamsheet/:id' element={<SheetByTeam />} />
                  <Route path='/create-user' element={<CreateUser />} />
                  <Route path='/login-logs' element={<LoginLogs />} />
                  <Route path='/update-logs' element={<UpdateLogs />} />
                  <Route path='/oldview' element={<OldView />} />
                  <Route path='/teammembers/:id' element={<TeamMembers />} />
                  <Route path='/member/:pk' element={<PersonalSheet />} />
                </Routes>
              </Layout>
            }
            {
              role === "manager" &&
              <Layout>
                <Routes>
                  <Route path='/updates' element={<Updates />} />
                  <Route path="/login" element={!access_token ? <Login /> : <Navigate to="/" />} />
                  <Route path='/manager-team' element={<ManagerTeam id={data.team_id} />} />
                  <Route path='/myenteries' element={<PersonalSheet id={data.employee} />} />
                  <Route path='/newenteries' element={<NewEnteries />} />
                  <Route path='/' element={<TeamManageView id={data.team_id} />} />
                  <Route path='/oldview' element={<ManagerOldView id={data.team_id} />} />
                </Routes>
              </Layout>
            }
            {
              role === "employee" &&
              <Layout>
                <Routes>
                  <Route path='/updates' element={<Updates />} />
                  <Route path="/login" element={!access_token ? <Login /> : <Navigate to="/" />} />
                  <Route path='/' element={<PersonalSheet id={data.employee} />} />
                  <Route path='/newenteries' element={<NewEnteries />} />
                  {/* <Route path='/oldview' element={<OldView />} /> */}
                </Routes>
              </Layout>
            }

            {/* <ChangePassword/> */}
          </>
          :
          <Login />
        }
      </BrowserRouter>

    </div>
  );
}

export default App;
