// DeleteConfirm.jsx
import React from 'react';
import "../styles/deletedata.scss";

const DeleteConfirm = ({ onDelete, onClose }) => {
    const handleDelete = () => {
        onDelete();
        onClose();
    };

    return (
        <div className='delete-wrapper glass'>
            <div className="delete-box">
                <p>Are you sure to delete this record ?</p>
                <div className="btns">
                <button className='dlt-btn' onClick={handleDelete}>Yes</button>
                <button className='dlt-btn' onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirm;
