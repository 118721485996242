import React, { useEffect, useState } from 'react'
import "../styles/editteamsheet.scss"
import { RxCross1 } from 'react-icons/rx';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const EditTeamSheet = (props) => {
  const [formData, setFormData] = useState(
    {
      approved_strategy: "",
      client_name: "",
      work_type: "",
      deliverable_type: "",
      projected_completion_date: "",
      deliverable_description: "N/A",
      deliverable_brief: "N/A",
      platform: "",
      type_of_campaign: "",
      Client_deliverables: "",
      Deliverable_duration: "N/A",
      Entry_by: "",
      sheet_owner: null,
      start_date: "",
      completion_date: "",
      approver_name: "",
      approval_made: "",
      status: null,
      overall_status: "",
      comments_log: "",
      related_team: null
    }
  )
  const navigate = useNavigate()
  const url = process.env.REACT_APP_URL
  const fetchData = () => {
    axios.get(`${url}tracker/${props.id}`)
      .then(res => setFormData(res.data))
      .catch()
  }
  const formatDate = (date) => {
    const formattedDate = dayjs(date).format('DD-MM-YYYY'); // Format date in YYYY-MM-DD format
    return formattedDate;
  };
  useEffect(() => { fetchData() }, [])
  // task status 
  const [status, setstatus] = useState([])
  const fetchTask = () => {
    axios.get(`${url}tasks/`)
      .then(res => setstatus(res.data))
      .catch()
  }

  // employee fetching
  const [employee, setemployee] = useState([])
  const fetchEmployee = () => {
    axios.get(`${url}employee/`)
      .then(res => setemployee(res.data))
      .catch()
  }
  // team name fetching
  const [team, setteam] = useState([])
  const fetchTeam = () => {
    axios.get(`${url}teams/`)
      .then(res => setteam(res.data))
      .catch()
  }
  const updateSheet = (e) => {
    e.preventDefault();
    props.refresh()
    axios.patch(`${url}tracker/${props.id}`, formData)
      .then(res => {
        createactivitylog();
        props.refresh();
        toast.success('Entry Updated', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Flip,
        });
        // navigate("/")
      })
      .catch()
  }
  // working of refresh or wha se pass kar diya hai bus yha pr fetch kr dena hai
  const user = localStorage.getItem("user_name")
  // activity logs 
  // activity log creations
  const [activitylog, setactivitylog] = useState({
    username: user,
    action: 'Updated',
    field_name: [],
    database_name: 'Tracker',
  })
  const createactivitylog = () => {
    axios.post(`${url}user-activity/`, { ...activitylog, changed_field_id: formData.id })
      .then()
      .catch()
  }
  // onfocus
  const handleFocus = (fieldName) => {
    setactivitylog((prevActivitylog) => ({
      ...prevActivitylog,
      field_name: [...prevActivitylog.field_name, fieldName],
    }));
  };
  // /////////////////
  // client name fetching
  const [data, setdata] = useState([])
  const fetchclientdata = () => {
    axios.get(`${url}clients/`)
      .then(res => setdata(res.data))
      .catch()
  }
  // work type fetching 
  const [worktype, setworktype] = useState([])
  const fetchworktype = () => {
    axios.get(`${url}work-type/`)
      .then(res => setworktype(res.data))
      .catch()
  }
  // deliverable type
  const [deliverable, setdeliverable] = useState([])
  const deliverabletype = () => {
    axios.get(`${url}deliverabletype/`)
      .then(res => setdeliverable(res.data))
      .catch()
  }
  // approvers names
  const [approvers, setapprovers] = useState([])
  const apprroverfetch = () => {
    axios.get(`${url}final-approvers/`)
      .then(res => setapprovers(res.data))
      .catch()
  }
  // 
  useEffect(() => {
    fetchTask();
    fetchEmployee();
    fetchTeam();
    fetchclientdata();
    fetchworktype();
    deliverabletype();
    apprroverfetch();
    fetchRole();
  }, [])
  const username = localStorage.getItem("user_name")
  const [empid,setempid] = useState()
  const [role, setrole] = useState('')
  const fetchRole = () => {
      axios.get(`${url}user-profile/${username}/`)
          .then(res => {setrole(res.data.role);setempid(res.data.employee)})
          .catch()
  }
  // console.log(role)
  console.log(username)
  return (
    <div className="teamedit-parent glass">
      <div className="teamedit-container">
        <p><RxCross1 onClick={props.close} /></p>
        <form action="/" onSubmit={updateSheet}>
          <TextField
            select
            label="Approved - Strategy / Plan (Y/N)"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.approved_strategy}
            onFocus={() => handleFocus('Approved - Strategy')}
            onChange={(e) => setFormData({ ...formData, approved_strategy: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >

            <MenuItem key="1" value="Yes">
              Yes
            </MenuItem>
            <MenuItem key="2" value="No">
              No
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Clients"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.client_name}
            onFocus={() => handleFocus('Clients')}
            onChange={(e) => setFormData({ ...formData, client_name: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >

            {data && data
              .sort((a, b) => a.client_name.localeCompare(b.client_name))
              .map((data, index) => (
                <MenuItem key={index} value={data.client_name}>
                  {data.client_name}
                </MenuItem>
              ))
            }

          </TextField>

          <TextField
            select
            label="Work Type"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.work_type}
            onFocus={() => handleFocus('Work Type')}
            onChange={(e) => setFormData({ ...formData, work_type: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >

            {worktype && worktype
              .sort((a, b) => a.work_type.localeCompare(b.work_type))
              .map((data, index) => (
                <MenuItem key={index} value={data.work_type}>
                  {data.work_type}
                </MenuItem>
              ))
            }

          </TextField>
          <TextField
            select
            label="Deliverable Type"
            variant="outlined"
            value={formData.deliverable_type}
            onChange={(e) => setFormData({ ...formData, deliverable_type: e.target.value })}
            fullWidth={false}
            className='text-input'
            margin="normal"
            onFocus={() => handleFocus('Deliverable Type')}
            InputLabelProps={{ shrink: true }}
          >

            {deliverable && deliverable
              .sort((a, b) => a.Deliverable_type.localeCompare(b.Deliverable_type))
              .map((data, index) => (
                <MenuItem key={index} value={data.Deliverable_type}>
                  {data.Deliverable_type}
                </MenuItem>
              ))
            }

          </TextField>
          <TextField
            id="outlined-completion-date"
            label="Projected Completion Date (DD/MM/YYYY)"
            defaultValue=""
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.projected_completion_date}
            InputLabelProps={{ shrink: true }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Set Projected Completion Date"
              variant="outlined"
              fullWidth={true}
              disabled={role == "employee"  || formData.sheet_owner == empid}
              className='text-input'
              format="DD/MM/YYYY"
              onFocus={() => handleFocus('Projected Completion Date')}
              onChange={(e) => setFormData({ ...formData, projected_completion_date: formatDate(e) })}
              margin="normal"
              InputLabelProps={{ shrink: true }} />
          </LocalizationProvider>

          <TextField

            id="outlined-deliverable-description"
            label="Deliverable Description"
            defaultValue="N/A"
            value={formData.deliverable_description}
            onFocus={() => handleFocus('Deliverable Description')}
            onChange={(e) => setFormData({ ...formData, deliverable_description: e.target.value })}
            InputProps={{
              //   readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="outlined-deliverable-brief"
            label="Deliverable Links"
            defaultValue="N/A"
            InputProps={{
              //   readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.deliverable_brief}
            onFocus={() => handleFocus('Deliverable Links')}
            onChange={(e) => setFormData({ ...formData, deliverable_brief: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            select
            id="outlined-platform"
            label="Platform"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.platform}
            onFocus={() => handleFocus('Platform')}
            onChange={(e) => setFormData({ ...formData, platform: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >
             <MenuItem value="Blog">
                            Blog
                        </MenuItem>
                        <MenuItem value="Facebook">
                            Facebook
                        </MenuItem>
                        <MenuItem value="Instagram">
                            Instagram
                        </MenuItem>
                        <MenuItem value="Landing Page">
                            Landing Page
                        </MenuItem>
                        <MenuItem value="Linked In">
                            Linked In
                        </MenuItem>
                        <MenuItem value="Multiple Platform">
                            Multiple Platform
                        </MenuItem>
                        <MenuItem value="Research">
                            Research
                        </MenuItem>
                        <MenuItem value="Twitter - X">
                            Twitter - X
                        </MenuItem>
                        <MenuItem value="Wordpress">
                            Wordpress
                        </MenuItem>
                        <MenuItem value="Website">
                            Website
                        </MenuItem>
                        <MenuItem value="Whatsapp">
                            Whatsapp
                        </MenuItem>
                        <MenuItem value="Youtube">
                            Youtube
                        </MenuItem>
                        <MenuItem value="Zoom">
                            Zoom
                        </MenuItem>
                        <MenuItem value="N/A">
                            N/A
                        </MenuItem>

          </TextField>


          <TextField
            id="outlined-campaign-type"
            label="Type of Campaign"
            defaultValue=""
            InputProps={{
              //   readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            onFocus={() => handleFocus('Type of Campaign')}
            value={formData.type_of_campaign}
            onChange={(e) => setFormData({ ...formData, type_of_campaign: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />


          <TextField
            select
            id="outlined-internal-client"
            label="Client Deliverables"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.Client_deliverables}
            onFocus={() => handleFocus('Client Deliverables')}
            onChange={(e) => setFormData({ ...formData, Client_deliverables: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >

            <MenuItem key="1" value="Internal">
              Internal
            </MenuItem>
            <MenuItem key="2" value="External">
              External
            </MenuItem>

          </TextField>

          <TextField
            id="outlined-deliverable-duration"
            label="Deliverable Duration (If Applicable)"
            defaultValue="N/A"
            InputProps={{
              //   readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.Deliverable_duration}
            onFocus={() => handleFocus('Deliverable Duration')}
            onChange={(e) => setFormData({ ...formData, Deliverable_duration: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            select
            id="outlined-entry-created-by"
            label="Entry Created By"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            onFocus={() => handleFocus('Entry Created By')}
            value={formData.Entry_by}
            onChange={(e) => setFormData({ ...formData, Entry_by: e.target.value })}
            InputLabelProps={{shrink: true}}
          >

            {employee && employee
              .sort((a, b) => a.employee_name.localeCompare(b.employee_name)) // Sort the employee array alphabetically
              .map((data, index) => (
                <MenuItem key={index} value={data.employee_name}>
                  {data.employee_name}
                </MenuItem>
              ))
            }
          </TextField>
          <TextField
            select
            id="outlined-owner"
            label="Owner"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.sheet_owner}
            onFocus={() => handleFocus('Owner')}
            onChange={(e) => {
              setFormData({ ...formData, sheet_owner: e.target.value })
            }}
            InputLabelProps={{ shrink: true }}
          >
            {employee && employee
              .sort((a, b) => a.employee_name.localeCompare(b.employee_name)) // Sort the employee array alphabetically
              .map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.employee_name}
                </MenuItem>
              ))
            }

          </TextField>
          <TextField
            id="outlined-completion-date"
            label="Start Date (DD/MM/YYYY)"
            defaultValue=""
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.start_date}
            InputLabelProps={{ shrink: true }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker id="outlined-start-date"
              label="Set Start Date "
              variant="outlined"
              fullWidth={false}
              className='text-input'
              format="DD/MM/YYYY"
              // value={formData.start_date}
              onFocus={() => handleFocus('Start Date')}
              onChange={(e) => setFormData({ ...formData, start_date: formatDate(e) })}
              InputLabelProps={{ shrink: true }}
              margin="normal" />
          </LocalizationProvider>
          <TextField
            id="outlined-completion-date"
            label="Completion Date (DD/MM/YYYY)"
            defaultValue=""
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.completion_date}
            InputLabelProps={{ shrink: true }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker id="outlined-completion-date"
              label="Set Completion Date"
              variant="outlined"
              fullWidth={false}
              className='text-input'
              format="DD/MM/YYYY"
              // value={formData.completion_date}
              onFocus={() => handleFocus('Completion Date')}
              onChange={(e) => setFormData({ ...formData, completion_date: formatDate(e) })}
              InputLabelProps={{ shrink: true }}
              margin="normal" />
          </LocalizationProvider>

          <TextField
            select
            id="outlined-final-approver-name"
            label="Final Approver Name"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            onFocus={() => handleFocus('Final Approver Name')}
            value={formData.approver_name}
            onChange={(e) => setFormData({ ...formData, approver_name: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >

            {approvers && approvers
              .sort((a, b) => a.Final_Approver.localeCompare(b.Final_Approver))
              .map((data, index) => (
                <MenuItem key={index} value={data.Final_Approver}>
                  {data.Final_Approver}
                </MenuItem>
              ))
            }

          </TextField>
          <TextField
            select
            id="outlined-final-approval-made"
            label="Final Approval Made (Y/N)"
            variant="outlined"
            fullWidth={false}
            disabled={role == "employee"  || formData.sheet_owner == empid}
            className='text-input'
            margin="normal"
            value={formData.approval_made}
            onFocus={() => handleFocus('Final Approval Made')}
            onChange={(e) => setFormData({ ...formData, approval_made: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >

            <MenuItem key="1" value="Yes">
              Yes
            </MenuItem>
            <MenuItem key="2" value="No">
              No
            </MenuItem>
          </TextField>
          <TextField
            select
            id="outlined-status"
            label="Status"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.status}
            onFocus={() => handleFocus('Status')}
            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >
            {status && status
                            .sort((a, b) => a.status_name.localeCompare(b.status_name)) // Sort the status array alphabetically
                            .map((data, index) => (
                                <MenuItem key={index} value={data.id}>
                                    {data.status_name}
                                </MenuItem>
                            ))
                        }
          </TextField>
          <TextField
            select
            id="outlined-overall-status"
            label="Overall Status"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.overall_status}
            onFocus={() => handleFocus('Overall Status')}
            onChange={(e) => setFormData({ ...formData, overall_status: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >

            <MenuItem key="1" value="Closed">
              Closed
            </MenuItem>
            <MenuItem key="2" value="Open">
              Open
            </MenuItem>
          </TextField>

          <TextField
            select
            id="outlined-final-approver-name"
            label="Team Name"
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.related_team}
            onFocus={() => handleFocus('Team Name')}
            onChange={(e) => setFormData({ ...formData, related_team: e.target.value })}
            InputLabelProps={{ shrink: true }}
          >
            {team && team.map((data, index) => {
              return (
                <MenuItem key={index} value={data.id}>
                  {data.team_name}
                </MenuItem>
              )
            })}
          </TextField>
          <TextField
            id="outlined-comments-log"
            label="Comments Log"
            defaultValue=""
            InputProps={{
              //   readOnly: true,
            }}
            variant="outlined"
            fullWidth={false}
            className='text-input'
            margin="normal"
            value={formData.comments_log}
            onFocus={() => handleFocus('Comments Log')}
            onChange={(e) => setFormData({ ...formData, comments_log: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
          <button className='submit-btn btn' type='submit'>
            Update
          </button>
        </form>

      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Flip"
      />
    </div>
  )
}

export default EditTeamSheet