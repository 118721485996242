import React, { useEffect, useState } from 'react'
import "../styles/createuser.scss"
import { TextField, MenuItem } from '@mui/material'
import { RxCross1 } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast, Flip } from 'react-toastify';
const CreateUser = () => {
    const [formdata, setformdata] = useState({
        username: "",
        password: "",
        employee: "",
        role: "",
        team_id: "",
    })
    const url = process.env.REACT_APP_URL
    const navigate = useNavigate()
    const submitHandler = (e) => {
        e.preventDefault();
        axios.post(`${url}user/create/`, formdata)
            .then(res => {
                toast.success('User Created', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    transition: Flip,
                });
                navigate("/manage")
            })
            .catch()
    }
    // employee fetching
    const [employee, setemployee] = useState([])
    const fetchEmployee = () => {
        axios.get(`${url}employee/`)
            .then(res => setemployee(res.data))
            .catch()
    }
    // team name fetching
    const [team, setteam] = useState([])
    const fetchTeam = () => {
        axios.get(`${url}teams/`)
            .then(res => setteam(res.data))
            .catch()
    }
    // 
    useEffect(() => {
        fetchTeam();
        fetchEmployee();
    }, [])
    return (
        <>
            <div className="createuser-wrapper glass">
                <form action="/" onSubmit={submitHandler}>
                    <div className="close-btn">
                        <Link to="/manage"><RxCross1 /></Link>
                    </div>
                    <TextField
                        id="useriD"
                        label="User Id"
                        type="text"
                        variant="outlined"
                        required
                        onChange={e => setformdata({ ...formdata, username: e.target.value })}
                    />
                    <TextField
                        select
                        fullWidth={true}
                        className='text-input'
                        margin="normal"
                        id="useriD"
                        label="User role"
                        type="text"
                        variant="outlined"
                        required
                        onChange={e => setformdata({ ...formdata, role: e.target.value })}
                    >

                        <MenuItem key="1" value="admin">
                            Admin
                        </MenuItem>
                        <MenuItem key="2" value="manager">
                            Manager
                        </MenuItem>
                        <MenuItem key="3" value="employee">
                            Employee
                        </MenuItem>
                    </TextField>
                    {formdata.role === "employee" &&
                        <TextField
                            select
                            fullWidth={true}
                            id="useriD"
                            label="Select Employee"
                            type="text"
                            variant="outlined"
                            className='text-input'
                            margin="normal"
                            required
                            onChange={e => setformdata({ ...formdata, employee: e.target.value })}
                        >
                            {
                                employee && employee.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data.id}>
                                            {data.employee_name}
                                        </MenuItem>
                                    )
                                })
                            }


                        </TextField>
                    }


                    {formdata.role === "manager" &&
                        <TextField
                            select
                            fullWidth={true}
                            id="useriD"
                            label="Select Team"
                            type="text"
                            variant="outlined"
                            className='text-input'
                            margin="normal"
                            required
                            onChange={e => setformdata({ ...formdata, team_id: e.target.value })}
                        >
                            {
                                team && team.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data.id}>
                                            {data.team_name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    }
                    {formdata.role === "manager" &&
                        <TextField
                            select
                            fullWidth={true}
                            id="useriD"
                            label="Select Employee"
                            type="text"
                            variant="outlined"
                            className='text-input'
                            margin="normal"
                            required
                            onChange={e => setformdata({ ...formdata, employee: e.target.value })}
                        >
                            {
                                employee && employee.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data.id}>
                                            {data.employee_name}
                                        </MenuItem>
                                    )
                                })
                            }


                        </TextField>

                    }

                    <TextField
                        id="useriD"
                        label="Password"
                        type="password"
                        variant="outlined"
                        required
                        onChange={e => setformdata({ ...formdata, password: e.target.value })}
                    />
                    <button className='btn'> Create User</button>
                </form>

            </div>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="Flip"
            />
        </>

    )
}

export default CreateUser