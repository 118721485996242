import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { CiLogin } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Flip } from 'react-toastify';
import "../styles/login.scss"
import { useLoginUserMutation } from '../services/userAuthApi';
import { CircularProgress } from '@mui/material';
import { getToken, storeToken } from '../services/LocalStorageService';
import { useDispatch } from 'react-redux';
import { setUserToken } from '../features/authSlice';
import axios from 'axios';
const Login = (props) => {
    const url = process.env.REACT_APP_URL
    const [toggle, setToggle] = useState(true);
    const [formdata, setFormdata] = useState({
        username: '',
        password: '',
    });
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loginUser, { isLoading }] = useLoginUserMutation()
    // passhandler
    const passHandler = () => {
        setToggle(!toggle);
    };
    // login by rtk
    const submitHandler = async (e) => {
        e.preventDefault();
        const res = await loginUser(formdata)
        if (res.error) {
            toast.error('Credentials not matched!', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Flip,
            });
        }
        if (res.data) {
            const { access, refresh, user } = res.data;
            storeToken(res.data)
            let { access_token } = getToken()
            dispatch(setUserToken({ access_token: access_token }))
            navigate('/');
            const username = localStorage.getItem("user_name");
            if (username) {
                loginupdate(username);
            }
        }
     
    }
    let { access_token } = getToken()
    useEffect(() => {
        dispatch(setUserToken({ access_token }));
    }, [access_token, dispatch]);
    
    
    const loginupdate = (username) => {
        axios.post(`${url}login-activity/`, {
            "username": username,
            "action": "Logged in"
          })
          .then()
          .catch()
      }
    return (
        <>
            <div className="login-parent">
                <div className="bg-overlay"></div>
                <form action="#" className="glass" onSubmit={submitHandler}>
                    <input
                        type="text"
                        placeholder="User Id"
                        value={formdata.username}
                        onChange={(e) => {
                            setFormdata({ ...formdata, username: e.target.value });
                        }}
                    />
                    <div className="pass-box">
                        <input
                            type={toggle ? 'password' : 'text'}
                            placeholder="Password"
                            className="pass"
                            value={formdata.password}
                            onChange={(e) => {
                                setFormdata({ ...formdata, password: e.target.value });
                            }}
                        />
                        <span onClick={passHandler}>{toggle ? <FaEye /> : <FaEyeSlash />}</span>
                    </div>
                    {isLoading ? <CircularProgress /> : <button className="btn">
                        Login <span><CiLogin /></span>
                    </button>}
                </form>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="Flip"
            />
        </>
    );
};

export default Login;
