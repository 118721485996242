const storeToken = (value) => 
{
    if(value)
    {
        const {access , refresh , username } = value
        localStorage.setItem('access_token',access)
        localStorage.setItem('refresh_token',refresh)
        localStorage.setItem('user_name',username)

    }
}

const getToken = () => 
{
    let access_token =  localStorage.getItem('access_token')
    let refresh_token =  localStorage.getItem('refresh_token')
    return {access_token,refresh_token}
}

const removeToken=()=> 
{
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("user_name")
}

export {storeToken,getToken,removeToken}