import React, { useEffect, useState } from 'react'
import "../styles/teammembers.scss"
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton';
import DeleteConfirm from './DeleteConfirm';
import { DataGrid } from '@mui/x-data-grid';
import { FaTrash } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { FaEye } from "react-icons/fa";
// skeleton
import Stack from '@mui/material/Stack';
import { ToastContainer, toast, Flip } from 'react-toastify';
const TeamMembers = () => {
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(true)
    const [deletedid,setdeletedid] = useState()
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const { id } = useParams();
    const url = process.env.REACT_APP_URL
    const fetchData = () => {
        axios.get(`${url}teams/${id}`)
            .then(res => setdata(res.data.team_members))
            .catch()
    }
    useEffect(() => {
        fetchData();
    }, [])
    const handleConfirmDelete = () => {
        axios.post(`${url}remove_employee_from_team/`,{
            "team_id": id,
            "employee_id": deletedid
        })
        .then(res => {fetchData()})
        .catch()
    };

    const closedelete = () => {
        setShowDeleteConfirmation(false);
    };

    const columns = [
        
        // { field: 'id', headerName: 'Serial no', width: 150 },
        { field: 'employee_id', headerName: 'Employee ID', width: 150 },
        { field: 'employee_name', headerName: 'Employee Name', width: 250 },
        { field: 'employee_designation', headerName: 'Employee Designation', width: 250 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='handle-buttons'>
                    <button style={{display:"none"}} className='action-btn'><FaEye /></button>
                    <button style={{display:"none"}}  className='action-btn' ><MdOutlinePassword /></button>
                    <button  className='action-btn' onClick={()=>{setShowDeleteConfirmation(true);setdeletedid(params.row.id)}}><FaTrash /></button>
                </div>
            ),
        },
    ];
    const rows = data && data.map((data)=>{
        return({
        id: data.id,
        employee_id:data.employee_id,
        employee_name:data.employee_name,
        employee_designation:data.employee_designation

        })});
    return (
        <div className='team-member-wrapper'>

          
            {loading ? <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                    },
                }}
                disableRowSelectionOnClick={true}
                hideFooterSelectedRowCount={true}
            /> : <>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                </Stack>
            </>}
          
            {showDeleteConfirmation && <DeleteConfirm onDelete={handleConfirmDelete} onClose={closedelete} />}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="Flip"
            />
        </div>


    )
}

export default TeamMembers