import React, { useEffect, useState } from 'react'
import Teamcard from '../components/Teamcard'
import "../styles/teams.scss"
import { IoMdAdd } from "react-icons/io";
import { Link } from 'react-router-dom';
import AddEmployee from '../modals/AddEmployee';
import axios from 'axios';
const Teams = () => {
  const [toggle, settoggle] = useState(false)
  const close = () => {
    settoggle(false)
  }
  const url = process.env.REACT_APP_URL
  const [data, setdata] = useState([])
  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}teams/`);
      setdata(response.data);
    } catch (error) {
      
    }
  };
  useEffect(()=>{
    fetchData()
  },[])
  return (
    <>

      {toggle ? <AddEmployee close={close} refresh={()=>fetchData()}/> :
        <div className='teams-wrapper'>
          <div className="teams-container">
            {
              data && data.map((data, index) => {
                return (
                  <Teamcard key={index} name={data.team_name} id={data.id} url={`/teamsheet/${data.id}`} refresh={()=>fetchData()}/>
                )
              })
            }

            <button className="teamcard-add glass" onClick={() => settoggle(true)}>
              <div className="teamcard-add-inner">
                <IoMdAdd />
              </div>
            </button>
          </div>
        </div>
      }
    </>
  )
}

export default Teams