import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "../styles/membersheet.scss"
// skeleton
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
const LoginLogs = () => {
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState([])
    const url = process.env.REACT_APP_URL
    const columns = [
        { field: 'id', headerName: 'Serial no', width: 150 },
        { field: 'user_id', headerName: 'User Name', width: 350 },
        { field: 'action', headerName: 'Action', width: 150 },
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'time', headerName: 'Time', width: 150 },
    ];
   
    const rows = data && data.reverse().map((item,index) =>{
        const dateTime = new Date(item.time);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateTime.toLocaleDateString('en-GB', options);
        const formattedTime = dateTime.toLocaleTimeString();
        return ({
        id: index+1,
        user_id: item.username,
        action: item.action,
        date: formattedDate,
        time: formattedTime })});
    const fetchLogin = () => {
        axios.get(`${url}login-activity/`)
            .then(res => { setdata(res.data); setloading(true) })
            .catch()
    }
    useEffect(() => {
        fetchLogin()
    }, [])
    const getRowClassName = (params) => {
        const status = params.row.action;
      
        if (status === "Logged in") {
          return "completed-row";
        }
        if (status === "Logged out") {
          return "delayed-row";
        }
  
      
        return ""; 
      };
    return (
        <>  <div className='member-sheet'>
            {loading ? <DataGrid
                rows={rows}
                columns={columns}
                getRowClassName={getRowClassName}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                disableRowSelectionOnClick={true}
                hideFooterSelectedRowCount={true}
            /> : <>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                </Stack>
            </>}
        </div>
        </>

    )
}

export default LoginLogs