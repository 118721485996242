import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "../styles/membersheet.scss"
import { FaTrash } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { FaEye } from "react-icons/fa";
// skeleton
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import DeleteConfirm from '../modals/DeleteConfirm';
import axios from 'axios';
import { ToastContainer, toast, Flip } from 'react-toastify';
import ChangePassword from '../modals/ChangePassword';
const MemberSheet = () => {
    const [loading, setloading] = useState(false)
    const [detail, setdetail] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [deletedUsername, setDeletedUsername] = useState(null);
    const [data,setdata] =  useState([])
    const url = process.env.REACT_APP_URL
    const columns = [
        
        // { field: 'id', headerName: 'Serial no', width: 150 },
        { field: 'user_id', headerName: 'User ID', width: 350 },
        { field: 'user_role', headerName: 'User Role', width: 250 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='handle-buttons'>
                    <button style={{display:"none"}} onClick={() => handleView(params.row.id)} className='action-btn'><FaEye /></button>
                    <button  className='action-btn' onClick={()=>{settoggle(true);setDeletedId(params.row.id)}}><MdOutlinePassword /></button>
                    <button onClick={() => {setShowDeleteConfirmation(true);setDeletedId(params.row.id);setDeletedUsername(params.row.user_id)}} className='action-btn'><FaTrash /></button>
                </div>
            ),
        },
    ];
    const handleView = (id) => {
        setdetail(true)
    };
    const handleConfirmDelete = () => {
        axios.delete(`${url}users/${deletedId}/`)
        .then(res => {toast.success('Access Removed', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Flip,
        });setShowDeleteConfirmation(false);fetchEmployee();postdeleteduser();})
        .catch()
        
    };

    const handleCloseDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const rows = data && data.map(item => ({
        id: item.id,
        user_id: item.username,
        user_role: item.role,
    }));
    
       
    ;
    const fetchEmployee=()=>{
        axios.get(`${url}users/`)
        .then(res =>{setloading(true);setdata(res.data)})
        .catch()
    }
    useEffect(()=>{
        fetchEmployee();
    },[])
    const [toggle,settoggle] = useState(false)
    const postdeleteduser=()=>
    {
        axios.post(`${url}deleted-users/`,
            {deleted_user:deletedUsername})
            .then()
            .catch()
    }
    return (
        <> <div className='member-sheet'>
            {loading ? <DataGrid
            className='datagrid'
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                disableRowSelectionOnClick={true}
                hideFooterSelectedRowCount={true}
            /> : <>
                <Stack spacing={1}>
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                </Stack>
            </>}
        </div>
            {showDeleteConfirmation && <DeleteConfirm onDelete={handleConfirmDelete} onClose={handleCloseDeleteConfirmation} />}
            {toggle && <ChangePassword close={()=>settoggle(false)} id={deletedId}/>}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="Flip"
            />
        </>

    )
}

export default MemberSheet