import React, { useEffect, useState } from 'react'
import "../styles/addmember.scss"
import { Link } from 'react-router-dom'
import { FaRegUserCircle } from "react-icons/fa";
import { TextField, MenuItem } from '@mui/material';
import { RxCross1 } from 'react-icons/rx';
import axios from 'axios';
const AddMember = () => {
    const [image, setImage] = useState(null);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            // Read the image file as a data URL
            reader.readAsDataURL(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Set the image in state
                setImage(reader.result);
            };
            // Read the image file as a data URL
            reader.readAsDataURL(file);
        }
    };
    // team name fetching
    const [team, setteam] = useState([])
    const url = process.env.REACT_APP_URL
    const fetchTeam = () => {
        axios.get(`${url}teams/`)
            .then(res => setteam(res.data))
            .catch()
    }
    useEffect(() => {
        fetchTeam();
    }, [])
    // form data sett
    const [formdata, setformdata] = useState({
        employee_team: [],
        employee_id: "",
        employee_name: "",
        employee_designation: "",
    })
    const formSubmit=(e)=>
    {
        e.preventDefault();
        axios.post(`${url}employee/`,formdata)
        .then( res => alert("employee created !"))
        .catch()

    }
    return (
        <div className="add_member_parent glass">
            <div className="addmember-container">
                <span className='exit-btn'> <Link to="/members"><RxCross1 /></Link></span>
                <form action="" onSubmit={formSubmit}>
                    <div className="form-left">
                        <TextField id="outlined-basic" label="Employee ID" variant="outlined" value={formdata.employee_id} className='input' onChange={(e) => setformdata({ ...formdata, employee_id: e.target.value })} />
                        <TextField id="outlined-basic" label="Employee Name" variant="outlined" className='input'
                            value={formdata.employee_name}
                            onChange={(e) => setformdata({ ...formdata, employee_name: e.target.value })}
                        />
                        <TextField
                            select
                            label="Team"
                            className='select'
                            value={formdata.employee_team}
                            onChange={(e) => setformdata({ ...formdata, employee_team: Array.isArray(e.target.value) ? e.target.value : [e.target.value] })}
                        >
                            {team && team.map((data) => {
                                return (
                                    <MenuItem key={data.id} value={data.id}>
                                        {data.team_name}
                                    </MenuItem>
                                )
                            })}



                        </TextField>
                        <TextField
                            select
                            label="Designation"
                            className='select'
                            value={formdata.employee_designation}
                            onChange={(e) => setformdata({ ...formdata, employee_designation: e.target.value })}
                        >

                            <MenuItem  value="BD Manager">
                                BD Manager
                            </MenuItem>
                            <MenuItem  value="BD Executive">
                                BD Executive
                            </MenuItem>
                            <MenuItem  value="HR Manager">
                                HR Manager
                            </MenuItem>
                            <MenuItem  value="Video Editor">
                                Video Editor
                            </MenuItem>
                            <MenuItem  value="Sr. Video Editor">
                                Sr. Video Editor
                            </MenuItem>
                            <MenuItem  value="Front-End developer">
                               Front-End Developer
                            </MenuItem>
                            <MenuItem  value="Back-End Developer">
                                Back-End Developer
                            </MenuItem>
                            <MenuItem  value="Full-Stack Developer">
                                Full-Stack Developer
                            </MenuItem>
                            <MenuItem  value="Digital Marketing Executive">
                                Digital Marketing Executive
                            </MenuItem>
                            <MenuItem  value="Website Developer">
                                Website Developer
                            </MenuItem>
                            <MenuItem  value="Social Media Marketing Specialist">
                                Social Media Marketing Specialist
                            </MenuItem>
                            <MenuItem  value=" Graphic Designer">
                                 Graphic Designer
                            </MenuItem>
                            <MenuItem  value="Sr. Graphic Designer">
                                Sr. Graphic Designer
                            </MenuItem>
                            <MenuItem  value="English Content Writer">
                                English Content Writer
                            </MenuItem>
                            <MenuItem  value="Accountant ">
                                Accountant
                            </MenuItem>
                            <MenuItem  value="Manager">
                                Manager
                            </MenuItem>
                            <MenuItem  value="SEO Consultant">
                                SEO Consultant
                            </MenuItem>
                            <MenuItem  value="Campaigning/ Performance Manager ">
                            Campaigning/ Performance Manager
                            </MenuItem>
                            <MenuItem  value="Marathi Content Writer">
                               Marathi Content Writer
                            </MenuItem>
                            <MenuItem value="Guest Relations Executive ">
                               Guest Relations Executive
                            </MenuItem>

                        </TextField>

                        <button className='btn submit-member-btn' type='submit'>Create</button>
                    </div>
                    {/* <div className="form-right">

                        {image && <img src={image} alt="Uploaded" />}

                        <input type="file" onChange={handleFileChange} className='file-input' />
 
                        {image ? null : <div
                            className="drag-drop-area"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <FaRegUserCircle />
                            <span>
                                Drag and drop or click to updload images.
                            </span>
                        </div>}
                    </div> */}
                </form>
            </div>
        </div>
    )
}

export default AddMember