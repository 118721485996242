import React, { useEffect, useState } from 'react'
import "../styles/sidebar.scss"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { TfiArrowCircleUp } from "react-icons/tfi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import Cookies from 'js-cookie';
import { removeToken } from '../services/LocalStorageService';
import { useDispatch } from 'react-redux';
import { unsetUserToken } from '../features/authSlice';
import axios from 'axios';
import { FaPowerOff } from "react-icons/fa6";
const Sidebar = () => {
    const url = process.env.REACT_APP_URL
    const dispatch = useDispatch()
    const [toggle, settoggle] = useState(false)
    const [toggle1, settoggle1] = useState(false)
    const [toggle2, settoggle2] = useState(false)
    const [toggle3, settoggle3] = useState(false)
    const [toggle4, settoggle4] = useState(false)
    const [toggle5, settoggle5] = useState(false)
    const navigate = useNavigate()
    const togglehandler = () => {
        settoggle(!toggle)
    }
    const togglehandler1 = () => {
        settoggle1(!toggle1)
    }
    const togglehandler2 = () => {
        settoggle2(!toggle2)
    }
    const togglehandler3 = () => {
        settoggle3(!toggle3)
    }
    const togglehandler4 = () => {
        settoggle4(!toggle4)
    }
    const togglehandler5 = () => {
        settoggle5(!toggle5)
    }
    const handleLogout = () => {
        dispatch(unsetUserToken({ access_token: null }))
        loginupdate()
        navigate("/login");
        removeToken()
    };
    // team name fetching
    const [team, setteam] = useState([])
    const fetchTeam = () => {
        axios.get(`${url}teams/`)
            .then(res => setteam(res.data))
            .catch()
    }
    const username = localStorage.getItem("user_name")
    const loginupdate = () => {
        axios.post(`${url}login-activity/`,
            {
                "username": username,
                "action": "Logged out"
            })
            .then()
            .catch()
    }
    // role cheaking 
    const [role, setrole] = useState('')
    const fetchRole = () => {
        axios.get(`${url}user-profile/${username}/`)
            .then(res => setrole(res.data.role))
            .catch()
    }
    useEffect(() => {
        fetchRole();
        fetchTeam();
        // fetchdeleteduser()
    }, [])
    // const [deleteduser, setdeleteduser] = useState([])
    // const fetchdeleteduser = () => {
    //     axios.get(`${url}deleted-users/`)
    //         .then(res => setdeleteduser(res.data))
    //         .catch(err => console.log(err))
    // }
    // useEffect(() => {
    //     if (deleteduser.some(user => user.deleted_user === username)) {
    //         loginupdate()
    //         removeToken()
    //         navigate("/login");
    //     }
    // }, []);
    // console.log(deleteduser.some(user => user.deleted_user === username))
    const location = useLocation()
    return (
        <div className="sidebar-wrapper">
            {role === "admin" &&
                <div className="sidebar-container">
                    <div className="img-logo-div">
                        <div className="img-logo">

                        </div>

                    </div>
                    <div className="version">  Version 1.0.3 </div>
                    <h2>Admin Panel</h2>
                    <div className="manager">
                        <div className={toggle ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler}> <Link to="/">Teams</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle ? "manager-menus" : "manager-menus1"}>
                            {team && team.map((item, index) => {
                                return (
                                    <Link key={index} to={`/teamsheet/${item.id}`} className={window.location.pathname == `/teamsheet/${item.id}` && 'active-link'}>{item.team_name}</Link>
                                )
                            })}
                        </div>
                    </div>
                    <div className="manager">
                        <div className={toggle1 ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler1}><Link to="/members">Employees</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle1 ? "manager-menus" : "manager-menus1"}>
                            <Link to="/members" className={window.location.pathname == '/members' && 'active-link'}>All Employees</Link>
                            <Link to="/addmembers">Add New</Link>
                        </div>
                    </div>
                    <div className="manager">
                        <div className={toggle2 ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler2}><Link to="/allenteries">Enteries</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle2 ? "manager-menus" : "manager-menus1"}>
                            <Link to='/allenteries' className={window.location.pathname == '/allenteries' && 'active-link'}>All Enteries</Link>
                            <Link to='/newenteries'>Add New</Link>
                        </div>
                    </div>
                    <div className="manager">
                        <div className={toggle4 ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler4}><Link to="/clients">Legends</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle4 ? "manager-menus" : "manager-menus1"}>
                            <Link to='/clients' className={window.location.pathname == '/clients' && 'active-link'}>Clients</Link>
                            <Link to='/worktype' className={window.location.pathname == '/worktype' && 'active-link'}>Work Type</Link>
                            <Link to='/deliverable-type' className={window.location.pathname == '/deliverable-type' && 'active-link'}>Deliveriable Type</Link>
                            <Link to='/approvers' className={window.location.pathname == '/approvers' && 'active-link'}>Final Approvers</Link>

                        </div>
                    </div>
                    <div className="manager">
                        <div className={toggle3 ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler3}><Link to="/manage">User Settings</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle3 ? "manager-menus" : "manager-menus1"}>
                            <Link to="/manage" className={window.location.pathname == '/manage' && 'active-link'}>Manage Users</Link>
                            <Link to="/create-user">Add New</Link>
                        </div>
                    </div>

                    <div className="manager">
                        <div className={toggle5 ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler5}><Link>User Logs</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle5 ? "manager-menus" : "manager-menus1"}>
                            <Link to="/login-logs" className={window.location.pathname == '/login-logs' && 'active-link'}>Login Logs</Link>
                            <Link to="/update-logs" className={window.location.pathname == '/update-logs' && 'active-link'}>Update Logs</Link>
                        </div>
                    </div>
                </div>
            }
            {role === "manager" &&
                <div className="sidebar-container">
                    <div className="img-logo-div">
                        <div className="img-logo">
                        </div>
                    </div>
                    <div className="version">  Version 1.0.3 </div>
                    <h2>Manager Panel</h2>
                    <div className="manager">
                        <div className={toggle ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler}> <Link to="/">Teams</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle ? "manager-menus" : "manager-menus1"}>
                            <Link to='/manager-team' className={window.location.pathname == '/manager-team' && 'active-link'}>Team Enteries</Link>
                        </div>
                    </div>
                    <div className="manager">
                        <div className={toggle2 ? "manager-toggle" : "manager-toggle1"} onClick={togglehandler2}><Link to="/myenteries">Enteries</Link> <span><TfiArrowCircleUp /></span></div>
                        <div className={toggle2 ? "manager-menus" : "manager-menus1"}>
                            <Link to='/myenteries' className={window.location.pathname == '/myenteries' && 'active-link'}>Your Enteries</Link>
                            <Link to='/newenteries'>Add New</Link>
                        </div>
                    </div>
                </div>
            }
            {role === "employee" &&
                <div className="sidebar-container">
                    <div className="img-logo-div">
                        <div className="img-logo">
                        </div>
                    </div>
                    <div className="version">  Version 1.0.3 </div>
                    <h2>Employee Panel</h2>
                    <div className="manager">
                        <div className="manager-menus">
                            <Link to='/' className={window.location.pathname == '/' && 'active-link'}>Enteries</Link>
                            <Link to='/newenteries'>Add New</Link>
                        </div>
                    </div>
                </div>
            }
            <div className="box-handler">
                <Link className='btn-update' to="/updates">Updates</Link>
                <span className='user-text'>{username}</span>
                <button className='logout-btn' onClick={handleLogout}>Logout <span><FaPowerOff /></span></button>
            </div>

        </div>
    )
}

export default Sidebar