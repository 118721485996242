import React, { useState } from 'react'
import "../styles/legendmodal.scss"
import { TextField } from '@mui/material'
import { RxCross1 } from 'react-icons/rx';
import axios from 'axios';
const WorkTypeModel = (props) => {
    const [data,setdata] = useState('')
    const url = process.env.REACT_APP_URL
    const postData=()=>
    {
        axios.post(`${url}work-type/`,
        {
            "work_type":data
        })
        .then(res=> {props.close();props.refresh()})
        .catch()
    }
    return (
        <div className='legend-layout'>
            
            <div className="form-box">
            <div className="close-btn">
                        <button onClick={props.close}><RxCross1 /></button>
             </div>
                <TextField
                    id="outlined-deliverable-duration"
                    label="Work Type"
                    InputProps={{
                        //   readOnly: true,
                    }}
                    variant="outlined"
                    fullWidth={false}
                    className='text-input'
                    margin="normal"
                    onChange={e=>setdata(e.target.value)}
                />
                <button className='btn' onClick={postData}>
                    Add
                </button>
            </div>
        </div>
    )
}

export default WorkTypeModel