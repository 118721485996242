import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx';
import TextField from '@mui/material/TextField';
import "../styles/newenteries.scss"
import { Button, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { ToastContainer, toast, Flip } from 'react-toastify';
import dayjs from 'dayjs';
const NewEnteries = () => {
    const [formData, setFormData] = useState({
        approved_strategy: "",
        client_name: "",
        work_type: "",
        deliverable_type: "",
        projected_completion_date: "",
        deliverable_description: "N/A",
        deliverable_brief: "N/A",
        platform: "",
        type_of_campaign: "N/A",
        Client_deliverables: "",
        Deliverable_duration: "N/A",
        Entry_by: "",
        sheet_owner: null, // Assuming this is an object reference
        start_date: "",
        completion_date: "",
        approver_name: "",
        approval_made: "No",
        status: null,
        overall_status: "",
        comments_log: "",
        related_team: null // Assuming this is an object reference
    });
    const formatDate = (date) => {
        const formattedDate = dayjs(date).format('DD-MM-YYYY'); // Format date in YYYY-MM-DD format
        return formattedDate;
    };
    const url = process.env.REACT_APP_URL
    // const submitHandler = (e) => {
    //     e.preventDefault();
    //     axios.post(`${url}tracker/`, formData)
    //         .then(res => {
    //             toast.success('Data Submitted', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: 'light',
    //                 transition: Flip,
    //             });
    //             setFormData(
    // {
    //     approved_strategy: "",
    //     client_name: "",
    //     work_type: "",
    //     deliverable_type: "",
    //     projected_completion_date: "",
    //     deliverable_description: "N/A",
    //     deliverable_brief: "N/A",
    //     platform: "",
    //     type_of_campaign: "",
    //     Client_deliverables: "",
    //     Deliverable_duration: "N/A",
    //     Entry_by: "",
    //     sheet_owner: null, // Assuming this is an object reference
    //     start_date: "",
    //     completion_date: "",
    //     approver_name: "",
    //     approval_made: "",
    //     status: null,
    //     overall_status: "",
    //     comments_log: "",
    //     related_team: null // 
    // }
    //             );
    //             createactivitylog();

    //         })
    //         .catch(err =>
    //             toast.warning('Please fill required fields.', {
    //                 position: 'top-center',
    //                 autoClose: 2000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: 'light',
    //                 transition: Flip,
    //             })
    //         )
    // }
    // task status 
    const navigate = useNavigate()
    const [repeat, setrepeat] = useState(1)
    const submitHandler = (e) => {
        e.preventDefault();
        const submitForm = () => {
            axios.post(`${url}tracker/`, formData)
                .then(res => {
                    // Handle successful submission
                    toast.success('Data Submitted', {
                        position: 'top-center',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        transition: Flip,
                    });
                    // Clear form data
                    setFormData({
                        approved_strategy: "",
                        client_name: "",
                        work_type: "",
                        deliverable_type: "",
                        projected_completion_date: "",
                        deliverable_description: "N/A",
                        deliverable_brief: "N/A",
                        platform: "",
                        type_of_campaign: "",
                        Client_deliverables: "",
                        Deliverable_duration: "N/A",
                        Entry_by: "",
                        sheet_owner: null, // Assuming this is an object reference
                        start_date: "",
                        completion_date: "",
                        approver_name: "",
                        approval_made: "",
                        status: null,
                        overall_status: "",
                        comments_log: "",
                        related_team: null // 
                    });
                    // navigate("/")
                    createactivitylog();
                })
                .catch(err => {
                    // Handle submission error
                    toast.warning('Please fill required fields.', {
                        position: 'top-center',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                        transition: Flip,
                    });
                    console.log(err)
                });
        };

        // Submit the form 5 times
        for (let i = 1; i <= parseInt(repeat); i++) {
            submitForm();
        }
    }
    // 
    const [status, setstatus] = useState([])
    const fetchTask = () => {
        axios.get(`${url}tasks/`)
            .then(res => setstatus(res.data))
            .catch()
    }
    useEffect(() => {
        fetchTask();
        fetchEmployee();
        fetchTeam();
        fetchclientdata();
        fetchworktype();
        deliverabletype();
        apprroverfetch();
        fetchRole();
    }, [])
    // employee fetching
    const [employee, setemployee] = useState([])
    const fetchEmployee = () => {
        axios.get(`${url}employee/`)
            .then(res => setemployee(res.data))
            .catch()
    }
    // team name fetching
    const [team, setteam] = useState([])
    const fetchTeam = () => {
        axios.get(`${url}teams/`)
            .then(res => setteam(res.data))
            .catch()
    }
    // client name fetching
    const [data, setdata] = useState([])
    const fetchclientdata = () => {
        axios.get(`${url}clients/`)
            .then(res => setdata(res.data))
            .catch()
    }
    // work type fetching 
    const [worktype, setworktype] = useState([])
    const fetchworktype = () => {
        axios.get(`${url}work-type/`)
            .then(res => setworktype(res.data))
            .catch()
    }
    // deliverable type
    const [deliverable, setdeliverable] = useState([])
    const deliverabletype = () => {
        axios.get(`${url}deliverabletype/`)
            .then(res => setdeliverable(res.data))
            .catch()
    }
    // approvers names
    const [approvers, setapprovers] = useState([])
    const apprroverfetch = () => {
        axios.get(`${url}final-approvers/`)
            .then(res => setapprovers(res.data))
            .catch()
    }
    // 
    const user = localStorage.getItem("user_name")
    // activity logs 
    const createactivitylog = () => {
        axios.post(`${url}user-activity/`, {
            username: user,
            action: 'Created',
            field_name: ["Entry"],
            database_name: 'Tracker',
            changed_field_id: 'N/A',
        })
            .then()
            .catch()
    }
    const username = localStorage.getItem("user_name")
    const [empid,setempid] = useState()
    const [role, setrole] = useState('')
    const fetchRole = () => {
        axios.get(`${url}user-profile/${username}/`)
            .then(res => {setrole(res.data.role);setempid(res.data.employee)})
            .catch()
    }
    return (
        <div className="addentery-parent glass">
            <div className="addentery-container">
                <Link to={role==="admin" && "/allenteries" || role ==="manager" && "/myenteries" || role === "employee" && "/"} className='back-btn'><RxCross1 /></Link>
                <form action="" onSubmit={submitHandler}>
                    <div className='count-box'>
                        <div className='repeat'>Repeat Enteries</div>
                        <button className='inc-dec-btn' variant="outlined" disabled={repeat <= 1} onClick={(e) => { e.preventDefault(); setrepeat(repeat - 1) }}> - </button>
                        <div className="count-btn">
                            {repeat}
                        </div>
                        <button className='inc-dec-btn' variant="outlined" onClick={(e) => { e.preventDefault(); setrepeat(repeat + 1) }}> + </button>
                    </div>
                    <TextField
                        required
                        select
                        label="Clients"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.client_name}
                        onChange={(e) => setFormData({ ...formData, client_name: e.target.value })}
                    >
                        {data && data
                            .sort((a, b) => a.client_name.localeCompare(b.client_name))
                            .map((data, index) => (
                                <MenuItem key={index} value={data.client_name}>
                                    {data.client_name}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                    <TextField
                        required
                        select
                        label="Approved - Strategy / Plan (Y/N)"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.approved_strategy}
                        onChange={(e) => setFormData({ ...formData, approved_strategy: e.target.value })}
                    >

                        <MenuItem key="1" value="Yes">
                            Yes
                        </MenuItem>
                        <MenuItem key="2" value="No">
                            No
                        </MenuItem>
                    </TextField>
                    <TextField
                        select
                        required
                        label="Work Type"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.work_type}
                        onChange={(e) => setFormData({ ...formData, work_type: e.target.value })}
                    >
                        {worktype && worktype
                            .sort((a, b) => a.work_type.localeCompare(b.work_type))
                            .map((data, index) => (
                                <MenuItem key={index} value={data.work_type}>
                                    {data.work_type}
                                </MenuItem>
                            ))
                        }


                    </TextField>
                    <TextField
                        select
                        required
                        label="Deliverable Type"
                        variant="outlined"
                        value={formData.deliverable_type}
                        onChange={(e) => setFormData({ ...formData, deliverable_type: e.target.value })}
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                    >
                        {deliverable && deliverable
                            .sort((a, b) => a.Deliverable_type.localeCompare(b.Deliverable_type))
                            .map((data, index) => (
                                <MenuItem key={index} value={data.Deliverable_type}>
                                    {data.Deliverable_type}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="Projected Completion Date"
                            variant="outlined"
                            fullWidth={false}
                            required
                            className='text-input'
                            format="DD/MM/YYYY"
                            // value={formData.projected_completion_date}
                            onChange={(e) => setFormData({ ...formData, projected_completion_date: formatDate(e) })}
                            margin="normal"
                            slotProps={{
                                textField: {
                                    required: true,
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <TextField
                        required
                        id="outlined-deliverable-description"
                        label="Deliverable Description"
                        defaultValue="N/A"
                        value={formData.deliverable_description}
                        onChange={(e) => setFormData({ ...formData, deliverable_description: e.target.value })}
                        InputProps={{
                            //   readOnly: true,
                        }}
                        variant="outlined"
                        multiline
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                    />

                    <TextField
                        id="outlined-deliverable-brief"
                        label="Deliverable Links"
                        defaultValue="N/A"
                        required
                        multiline
                        InputProps={{
                            //   readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.deliverable_brief}
                        onChange={(e) => setFormData({ ...formData, deliverable_brief: e.target.value })}
                    />
                    <TextField
                        select
                        required
                        id="outlined-platform"
                        label="Platform"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.platform}
                        onChange={(e) => setFormData({ ...formData, platform: e.target.value })}
                    >

                        <MenuItem value="Blog">
                            Blog
                        </MenuItem>
                        <MenuItem value="Facebook">
                            Facebook
                        </MenuItem>
                        <MenuItem value="Instagram">
                            Instagram
                        </MenuItem>
                        <MenuItem value="Landing Page">
                            Landing Page
                        </MenuItem>
                        <MenuItem value="Linked In">
                            Linked In
                        </MenuItem>
                        <MenuItem value="Multiple Platform">
                            Multiple Platform
                        </MenuItem>
                        <MenuItem value="Research">
                            Research
                        </MenuItem>
                        <MenuItem value="Twitter - X">
                            Twitter - X
                        </MenuItem>
                        <MenuItem value="Wordpress">
                            Wordpress
                        </MenuItem>
                        <MenuItem value="Website">
                            Website
                        </MenuItem>
                        <MenuItem value="Whatsapp">
                            Whatsapp
                        </MenuItem>
                        <MenuItem value="Youtube">
                            Youtube
                        </MenuItem>
                        <MenuItem value="Zoom">
                            Zoom
                        </MenuItem>
                        <MenuItem value="N/A">
                            N/A
                        </MenuItem>
                    </TextField>


                    <TextField
                        id="outlined-campaign-type"
                        label="Type of Campaign"
                        defaultValue="N/A"
                        required
                        InputProps={{
                            //   readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.type_of_campaign}
                        onChange={(e) => setFormData({ ...formData, type_of_campaign: e.target.value })}
                    />


                    <TextField
                        select
                        required
                        id="outlined-internal-client"
                        label="Client Deliverables"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.Client_deliverables}
                        onChange={(e) => setFormData({ ...formData, Client_deliverables: e.target.value })}
                    >

                        <MenuItem key="1" value="Internal">
                            Internal
                        </MenuItem>
                        <MenuItem key="2" value="External">
                            External
                        </MenuItem>

                    </TextField>

                    <TextField
                        required
                        id="outlined-deliverable-duration"
                        label="Deliverable Duration (If Applicable)"
                        defaultValue="N/A"
                        InputProps={{
                            //   readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.Deliverable_duration}
                        onChange={(e) => setFormData({ ...formData, Deliverable_duration: e.target.value })}
                    />

                    <TextField
                        select
                        id="outlined-entry-created-by"
                        required
                        label="Entry Created By"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.Entry_by}
                        onChange={(e) => setFormData({ ...formData, Entry_by: e.target.value })}
                    >

                        {employee && employee
                            .sort((a, b) => a.employee_name.localeCompare(b.employee_name)) // Sort the employee array alphabetically
                            .map((data, index) => (
                                <MenuItem key={index} value={data.employee_name}>
                                    {data.employee_name}
                                </MenuItem>
                            ))
                        }

                    </TextField>
                    <TextField
                        select
                        required
                        id="outlined-owner"
                        label="Owner"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.sheet_owner}
                        onChange={(e) => {
                            setFormData({ ...formData, sheet_owner: e.target.value })
                        }}
                    >
                        {employee && employee
                            .sort((a, b) => a.employee_name.localeCompare(b.employee_name)) // Sort the employee array alphabetically
                            .map((data, index) => (
                                <MenuItem key={index} value={data.id}>
                                    {data.employee_name}
                                </MenuItem>
                            ))
                        }

                    </TextField>
                    <TextField
                        select
                        required
                        id="outlined-final-approver-name"
                        label="Final Approver Name"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.approver_name}
                        onChange={(e) => setFormData({ ...formData, approver_name: e.target.value })}
                    >
                        {approvers && approvers
                            .sort((a, b) => a.Final_Approver.localeCompare(b.Final_Approver)) // Sort the approvers array alphabetically
                            .map((data, index) => (
                                <MenuItem key={index} value={data.Final_Approver}>
                                    {data.Final_Approver}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker id="outlined-start-date"
                            label="Start Date"
                            variant="outlined"
                            fullWidth={false}
                            className='text-input'
                            format="DD/MM/YYYY"
                            // value={formData.start_date}
                            onChange={(e) => setFormData({ ...formData, start_date: formatDate(e) })}
                            margin="normal"
                            slotProps={{
                                textField: {
                                    required: false,
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker id="outlined-completion-date"
                            label="Completion Date"
                            variant="outlined"
                            fullWidth={false}
                            className='text-input'
                            format="DD/MM/YYYY"
                            // value={formData.completion_date}
                            onChange={(e) => setFormData({ ...formData, completion_date: formatDate(e) })}
                            margin="normal" />
                    </LocalizationProvider>
                    <TextField
                        select
                        id="outlined-final-approval-made"
                        label="Final Approval Made (Y/N)"
                        required
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        defaultValue="No"
                        disabled={role == "employee"  || formData.sheet_owner == empid}
                        margin="normal"
                        value={formData.approval_made}
                        onChange={(e) => setFormData({ ...formData, approval_made: e.target.value })}
                    >
                        <MenuItem key="1" value="Yes">
                            Yes
                        </MenuItem>
                        <MenuItem key="2" value="No">
                            No
                        </MenuItem>
                    </TextField>
                    <TextField
                        select
                        required
                        id="outlined-status"
                        label="Status"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.status}
                        onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                    >
                        {status && status
                            .sort((a, b) => a.status_name.localeCompare(b.status_name)) // Sort the status array alphabetically
                            .map((data, index) => (
                                <MenuItem key={index} value={data.id}>
                                    {data.status_name}
                                </MenuItem>
                            ))
                        }

                    </TextField>
                    <TextField
                        select
                        id="outlined-overall-status"
                        required
                        label="Overall Status"
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.overall_status}
                        onChange={(e) => setFormData({ ...formData, overall_status: e.target.value })}
                    >

                        <MenuItem key="1" value="Closed">
                            Closed
                        </MenuItem>
                        <MenuItem key="2" value="Open">
                            Open
                        </MenuItem>
                    </TextField>

                    <TextField
                        select
                        id="outlined-final-approver-name"
                        label="Team Name"
                        variant="outlined"
                        required
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.related_team}
                        onChange={(e) => setFormData({ ...formData, related_team: e.target.value })}
                    >
                        {team && team.map((data, index) => {
                            return (
                                <MenuItem key={index} value={data.id}>
                                    {data.team_name} 
                                </MenuItem>
                            )
                        })}
                    </TextField>
                    <TextField
                        id="outlined-comments-log"
                        label="Comments Log"
                        multiline
                        defaultValue=""
                        InputProps={{
                            //   readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth={false}
                        className='text-input'
                        margin="normal"
                        value={formData.comments_log}
                        onChange={(e) => setFormData({ ...formData, comments_log: e.target.value })}
                    />
                    <button className='submit-btn btn' type='submit'>
                        Create
                    </button>
                </form>

            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="Flip"
            />
        </div>
    )
}

export default NewEnteries