import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import "../styles/taskstatus.scss"
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
const TaskStatusModel = (props) => {
    const [detail, setdetail] = useState(false)
    const [edit, setedit] = useState(false)
    const [deletedata, setdeletedata] = useState(false)
    const [id, setid] = useState(null)
    const url = process.env.REACT_APP_URL
    const getRowClassName = (params) => {
        const status = params.row.status;

        if (status === "completed") {
            return "completed-row";
          }
          if (status === ("delayed") || status === ("In Progress - Delayed"))  {
            return "delayed-row";
          }
          if (status === ("pending") || status === ("To Be Started")){
            return "pending-row";
          }

        return "";
    };
    // fetching
    const { id: teamid } = useParams()
    const columns = [
        // { field: 'serial_no', headerName: 'Serial no', width: 100 },
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'Client_name', headerName: 'Client Name', width: 130 },
        { field: 'Owner', headerName: 'Owner', width: 130 },
        { field: 'Start_Date', headerName: 'Start Date', width: 130 },
        {
            field: 'End_Date',
            headerName: 'End Date',
            width: 130,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
        },
        {
            field: 'Deliverable_brief',
            headerName: 'Deliverable Links',
            width: 250,
            renderCell: (params) => {
                // Check if the cell value is a valid URL
                const isLink = /^(ftp|http|https):\/\/[^ "]+$/.test(params.value);
            
                // If it's a link, render it as a clickable link; otherwise, render it as plain text
                return isLink ? (
                  <a href={params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                  </a>
                ) : (
                  <span>{params.value}</span>
                );
            },
        },
        {
            field: 'Deliverable_description',
            headerName: 'Deliverable Description',
            width: 250,
        },
        {
            field: 'feedback',
            headerName: 'Feedback',
            sortable: false,
            valueGetter: (params) => params.row.feedback,
            width: 200,
        },
  
    ];
    const handleView = (pid) => {
        // Implement your edit logic here
        setid(pid)
        setdetail(true)
    };
    const handleEdit = (pid) => {
        // Implement your edit logic here
        setid(pid)
        setedit(true)
    };

    const handleDelete = () => {
        axios.delete(`${url}tracker/${id}`)
            .then()
            .catch()
    };
    const rows = props.fdata.reverse().map((data, index) => {
        return ({
            serial_no: index + 1, id: data.id, Client_name: data.client_name, Owner: data.sheet_owner_name ? data.sheet_owner_name : "N/A", Start_Date: data.start_date, End_Date: data.completion_date, status: data.status_name, Deliverable_brief: data.deliverable_brief, Deliverable_description:data.deliverable_description,feedback: data.comments_log
        })
    });
    const navigate = useNavigate()
    const CustomToolbar = () => {
        return (
            <div className="button-custom" style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "0px 10px" }}>
                <Button style={{ width: "fit-content" }} variant="contained" color="primary" onClick={()=>{
                    if(props.id == "1")
                    {
                        props.close()
                    }
                    else if(props.id == "2")
                    {
                        props.close2()
                    }
                    else if(props.id == "3")
                    {
                        props.close3()
                    }
                    else if(props.id == "4")
                    {
                        props.close4()
                    }
                }}>
                    Back
                </Button>
            </div>
        );
    };
    return (
        <div className='task-status-wrapper glass'>

            <div className="wrapper-back">
                <DataGrid
                className={getRowClassName}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    disableRowSelectionOnClick={true}
                    hideFooterSelectedRowCount={true}
                    slots={{
                        toolbar: () => (
                            <React.Fragment>
                                <GridToolbar />
                                <CustomToolbar />
                            </React.Fragment>
                        ),
                    }}
                />
            </div>


        </div>
    )
}

export default TaskStatusModel