import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import "../styles/allenteries.scss"
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";

// skeleton
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import DeleteConfirm from '../modals/DeleteConfirm';
import TeamDetailView from '../modals/TeamDetailView';
import EditTeamSheet from '../modals/EditTeamSheet';
import axios from 'axios';
import { DataGridPro } from '@mui/x-data-grid-pro';
const ManagerOldView = (props) => {
    const [loading, setloading] = useState(false)
    const [detail, setdetail] = useState(false)
    const [edit, setedit] = useState(false)
    const [deletedata, setdeletedata] = useState(false)
    const [id, setid] = useState(null)
    const [data, setdata] = useState([])
    useEffect(() => {
        fetchData();
    }, [])
    // completion row logic
    const getRowClassName = (params) => {
        const status = params.row.status;

        if (status === "completed") {
            return "completed-row";
          }
          if (status === ("delayed") || status === ("In Progress - Delayed"))  {
            return "delayed-row";
          }
          if (status === ("pending") || status === ("To Be Started")){
            return "pending-row";
          }

        return "";
    };
    // fetching
    const url = process.env.REACT_APP_URL
    const fetchData = () => {
            axios.get(`${url}team/${props.id}/tracker/`)
            .then(res => { setloading(true); setdata(res.data) })
            .catch()
    }

    const columns = [
 
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'Client_name', headerName: 'Client Name', width: 130 },
        { field: 'Owner', headerName: 'Owner', width: 130 },

        { field: 'Start_Date', headerName: 'Start Date', width: 130 },
        {
            field: 'End_Date',
            headerName: 'Completion Date',
            width: 150,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
        },
        {
            field: 'team',
            headerName: 'Team Name',
            width: 130,
        },
        // 
        {
            field: 'approved_strategy',
            headerName: 'Approved Strategy / Plan',
            width: 200,
        },
        {
            field: 'work_type',
            headerName: 'Work Type',
            width: 130,
        },
        {
            field: 'deliverable_type',
            headerName: 'Deliverable Type',
            width: 130,
        },
        {
            field: 'projected_completion_type',
            headerName: 'Projected Completion Date',
            width: 250,
        },
        {
            field: 'delieverable_description',
            headerName: 'Deliverable Description',
            width: 180,
        },
        {
            field: 'platform',
            headerName: 'Platform',
            width: 130,
        },
        {
            field: 'type_of_campaign',
            headerName: 'Type Of Campaign',
            width: 180,
        },
        {
            field: 'client_deliverables',
            headerName: 'Client Deliverables',
            width: 180,
        },
        {
            field: 'deliverable_duration',
            headerName: 'Deliverable Duration',
            width: 180,
        },
        {
            field: 'entry_created_by',
            headerName: 'Entry Created By',
            width: 150,
        },
        {
            field: 'entry_created_on',
            headerName: 'Entry Created On',
            width: 150,
        },
        {
            field: 'approver_name',
            headerName: 'Approver Name',
            width: 130,
        },
        {
            field: 'approval_made',
            headerName: 'Approval Made',
            width: 130,
        },
        {
            field: 'overall_status',
            headerName: 'Overall Status',
            width: 130,
        },
        // 
        {
            field: 'Deliverable_brief',
            headerName: 'Deliverable Links',
            width: 250,
            renderCell: (params) => {
                // Check if the cell value is a valid URL
                const isLink = /^(ftp|http|https):\/\/[^ "]+$/.test(params.value);
            
                // If it's a link, render it as a clickable link; otherwise, render it as plain text
                return isLink ? (
                  <a href={params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                  </a>
                ) : (
                  <span>{params.value}</span>
                );
            },
        },
        {
            field: 'feedback',
            headerName: 'Feedback',
            sortable: false,
            valueGetter: (params) => params.row.feedback,
            width: 200,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='handle-buttons'>
                    <button style={{display : "none"}} onClick={() => handleView(params.row.id)} className='action-btn'><FaEye /></button>
                    <button onClick={() => handleEdit(params.row.id)} className='action-btn'><MdEdit /></button>
                    <button onClick={() => { setdeletedata(true); setid(params.row.id) }} className='action-btn'><FaTrash /></button>
                </div>
            ),
        },
    ];
    const handleView = (pid) => {
        // Implement your edit logic here
        setid(pid)
        setdetail(true)
    };
    const handleEdit = (pid) => {
        // Implement your edit logic here
        setid(pid)
        setedit(true)
    };

    const handleDelete = () => {
        // Implement your delete logic here
        axios.delete(`${url}tracker/${id}`)
            .then(res => { fetchData(); createactivitylog() })
            .catch()
    };
    const user = localStorage.getItem("user_name")
    // activity logs 
    // activity log creations
    const [activitylog, setactivitylog] = useState({
        username: user,
        action: 'Deleted',
        field_name: ["Entry"],
    })
    const createactivitylog = () => {
        axios.post(`${url}user-activity/`, { ...activitylog, changed_field_id: id })
            .then()
            .catch()
    }
    // onfocus
    const handleFocus = (fieldName) => {
        setactivitylog((prevActivitylog) => ({
            ...prevActivitylog,
            field_name: [...prevActivitylog.field_name, fieldName],
        }));
    };
    // /////////////////
    const close = () => {
        setdetail(false)
    }
    const closeedit = () => {
        setedit(false)
    }
    const reedit = () => {
        setedit(true)
        setdetail(false)
    }
    const closedelete = () => {

        setdeletedata(false)
    }
    const formatDatetime = (datetimeString) => {
        const datetime = new Date(datetimeString); // Convert the datetime string to a Date object
        const day = datetime.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
        const month = (datetime.getMonth() + 1).toString().padStart(2, '0'); // Get the month (Note: month is zero-based) and pad with leading zero if needed
        const year = datetime.getFullYear(); // Get the year
      
        return `${day}/${month}/${year}`; // Return the formatted date string
      };
    const rows = data.reverse().map((data, index) => {
        return ({
            serial_no: index + 1, id: data.id, Client_name: data.client_name, Owner: data.sheet_owner_name ? data.sheet_owner_name : "N/A", Start_Date: data.start_date, End_Date: data.completion_date, status: data.status_name, Deliverable_brief: data.deliverable_brief, feedback: data.comments_log,
            team: data.related_team_name ? data.related_team_name : "N/A",
            approved_strategy:data.approved_strategy,
            work_type: data.work_type,
            deliverable_type:data.deliverable_type,
            projected_completion_type: data.projected_completion_date,
            delieverable_description: data.deliverable_description,
            platform: data.platform,
            type_of_campaign: data.type_of_campaign,
            client_deliverables: data.Client_deliverables,
            deliverable_duration: data.Deliverable_duration,
            entry_created_by: data.Entry_by,
            entry_created_on: formatDatetime(data.Entry_created_on),
            approver_name: data.approver_name,
            approval_made:data.approval_made,
            overall_status:data.overall_status,
        })
    })
        ;
    return (
        <>
            <div className='team-sheet'>
                {loading ? <DataGridPro
                    getRowClassName={getRowClassName}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    disableRowSelectionOnClick={true}
                    hideFooterSelectedRowCount={true}
                    slots={{ toolbar: GridToolbar }}
                /> : <>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    </Stack>
                </>}
            </div>
            {detail && <TeamDetailView close={close} reedit={reedit} id={id} />}
            {edit && <EditTeamSheet close={closeedit} id={id} refresh={fetchData}/>}
            {deletedata && <DeleteConfirm onDelete={handleDelete} onClose={closedelete} />}
        </>
    )
}

export default ManagerOldView