import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField } from '@mui/material';
import { RxCross1 } from 'react-icons/rx';
import "../styles/changepassword.scss"
import { ToastContainer, toast, Flip } from 'react-toastify';
const ChangePassword = (props) => {
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (pass !== '' && pass2 !== '') {
      setHide(pass !== pass2);
    } else {
      setHide(true);
    }
  }, [pass, pass2]);

  const handlePasswordChange = (e, setPassword) => {
    setPassword(e.target.value);
    setHide(false); // Show error message immediately upon typing
  };
  const url = process.env.REACT_APP_URL
  const changePassword = (e) => {
    e.preventDefault();
    if (!hide) {
        axios.patch(`${url}users/${props.id}/update-password/`, { password: pass })
          .then(() => {
            toast.success('Password Changed', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Flip,
          })
            setPass("");
            setPass2("");
          })
          .catch((err) => {alert("Please fix error")});
      } 
  };

  return (
    <div className='change-password glass'>
      <form onSubmit={changePassword}>
        <div className="close-btn">
          <span onClick={props.close}><RxCross1 /></span>
        </div>
        <TextField
          id="pass"
          label="New Password"
          type="password"
          variant="outlined"
          value={pass}
          onChange={(e) => handlePasswordChange(e, setPass)}
        />
        <TextField
          id="pass2"
          label="Confirm Password"
          type="password"
          variant="outlined"
          value={pass2}
          onChange={(e) => handlePasswordChange(e, setPass2)}
        />
        {(hide && pass !='' && pass2 != '') && <span className='pass-err'>Passwords do not match</span>}
        <button className='btn' type='submit' disabled={hide}>Change</button>
      </form>
      <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={1}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="Flip"
            />
    </div>
  );
};

export default ChangePassword;
