import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "../styles/membersheet.scss"
// skeleton
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
const UpdateLogs = () => {
  const [loading, setloading] = useState(true)
  const [data, setdata] = useState([])
  const url = process.env.REACT_APP_URL
  const columns = [
      { field: 'id', headerName: 'Serial no', width: 150 },
      { field: 'user_id', headerName: 'User Name', width: 150 },
      { field: 'field_id', headerName: 'Changed Field Id', width: 150 },
      { field: 'field_name', headerName: 'Field Name', width: 250 },
      { field: 'changed_time', headerName: 'Time', width: 150 },
      { field: 'action', headerName: 'Action', width: 150 },
      { field: 'changed_date', headerName: 'Date', width: 150 },
  ];
 
  const rows = data && data.reverse().map((item,index) =>{
      const dateTime = new Date(item.time);
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const formattedDate = dateTime.toLocaleDateString('en-GB', options);
      const formattedTime = dateTime.toLocaleTimeString();
      const uniqueValuesSet = new Set(item.field_name);
      const uniqueArray = [...uniqueValuesSet];
      return ({
      id: index+1,
      user_id: item.username,
      field_id: item.changed_field_id,
      field_name: uniqueArray,
      action: item.action,
      changed_time: formattedTime,
      changed_date:formattedDate,
    })});
  const fetchLogin = () => {
      axios.get(`${url}user-activity/`)
          .then(res => { setdata(res.data); setloading(true) })
          .catch()
  }
  useEffect(() => {
      fetchLogin()
  }, [])
  const getRowClassName = (params) => {
      const status = params.row.action;
    
      if (status === "Logged in") {
        return "completed-row";
      }
      if (status === "Logged out") {
        return "delayed-row";
      }

    
      return ""; 
    };
  return (
      <>  <div className='member-sheet'>
          {loading ? <DataGrid
              rows={rows}
              columns={columns}
              getRowClassName={getRowClassName}
              initialState={{
                  pagination: {
                      paginationModel: { page: 0, pageSize: 25 },
                  },
              }}
              disableRowSelectionOnClick={true}
              hideFooterSelectedRowCount={true}
          /> : <>
              <Stack spacing={1}>
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                  <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
              </Stack>
          </>}
      </div>
      </>

  )
}

export default UpdateLogs