import React, { useEffect, useState } from 'react'
import Teamcard from '../components/Teamcard'
import "../styles/teams.scss"
import { IoMdAdd } from "react-icons/io";
import { Link } from 'react-router-dom';
import AddEmployee from '../modals/AddEmployee';
import axios from 'axios';
import TaskStatusModel from '../modals/TaskStatusModel';
const TeamManageView = (props) => {
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [data, setData] = useState([])
  const [completed, setCompletedTasks] = useState([]);
  const [pending, setPendingTasks] = useState([]);
  const [delayed, setDelayedTasks] = useState([]);
  const [entriesCreatedToday, setEntriesCreatedToday] = useState([]);
  const url = process.env.REACT_APP_URL
  const fetchData = () => {
    axios.get(`${url}team/${props.id}/tracker/`)
      .then(res => { setData(res.data) })
      .catch()
  }
  useEffect(() => {
    fetchData()
  }, [])
  // Process data to count tasks
  // Filter tasks based on status
  const pendingTasks = data.filter(task => task.status_name === 'pending' || task.status_name === 'To Be Started').length;
  const delayedTasks = data.filter(task => task.status_name === 'delayed' || task.status_name === 'In Progress - Delayed').length;
  const completedTasks = data.filter(task => task.status_name === 'completed').length;
  const totalTasks = data.length;
  useEffect(() => {
    // Set filtered tasks to state variables
    setCompletedTasks(completedTasks);
    setPendingTasks(pendingTasks);
    setDelayedTasks(delayedTasks);
  }, [data]);
  const close = () => {
    setToggle(false)
  }
  const close2 = () => {
    setToggle2(false)
  }
  const close3 = () => {
    setToggle3(false)
  }
  const close4 = () => {
    setToggle4(false)
  }
  useEffect(() => {
    // Filter entries created today
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const entriesToday = data.filter(task => task.Entry_created_on.startsWith(today));
    setEntriesCreatedToday(entriesToday);
  }, [data]);
  return (
    <div style={{display: "flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
      <div className="teamcard-parent glass">
        <div className="teamcard-left">
          <div className="task-status">
            <span className='pending-row' onClick={() => setToggle2(true)}>Pending: {pendingTasks}</span>
            <span className='delayed-row' onClick={() => setToggle3(true)}>Delayed: {delayedTasks}</span>
            <span className='completed-row' onClick={() => setToggle(true)}>Completed: {completedTasks}</span>
            <span onClick={() => setToggle4(true)}>New Enteries : {entriesCreatedToday.length} </span>
          </div>
          <p>{props.name}</p>
        </div>
        <div className="teamcard-right">
          <span>{totalTasks}</span>
          <p>entries</p>
        </div>
      </div>
      {toggle && <TaskStatusModel fdata={data.filter(task => task.status_name === 'completed')} close={close} id="1" />}
      {toggle2 && <TaskStatusModel fdata={data.filter(task => task.status_name === 'pending' || task.status_name === 'To Be Started')} close2={close2} id="2" />}
      {toggle3 && <TaskStatusModel fdata={data.filter(task => task.status_name === 'delayed' || task.status_name === 'In Progress - Delayed')} close3={close3} id="3" />}
      {toggle4 && <TaskStatusModel fdata={entriesCreatedToday} close4={close4} id="4" />}
    </div>
  )
}

export default TeamManageView