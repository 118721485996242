import React from 'react'
import "./updates.scss"
const Updates = () => {
  return (
    <div className='updates-wrapper'>
      <div className="updates-container">
        <div className="inner-div">
          <h2><span>***</span>Important Updates<span>***</span></h2>
          <ol>
            <li>Deliverable brief fields are now changed in deliverable links, where project links will be submitted.</li>
            <li>From now on, all form fields will be required to avoid any further confusion.</li>
            <li>We have added the ability to create multiple copies of the sheet while creating new entries by choosing the number of sheets from "Repeat Enteries".</li>
            <li>We have added the auto-refresh functionality after anyone updates the sheet.</li>
            <li>We have added an updates page where you can see all the changes made in the late update.</li>
            <li>Some new variables have been added to the platform options.</li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default Updates