import React, { useEffect, useState } from 'react'
import "../styles/teamcard.scss"
import { Link } from 'react-router-dom'
import axios from 'axios'
import Test from '../pages/Test'
import TaskStatusModel from '../modals/TaskStatusModel'
import { FaTrash } from "react-icons/fa";
import DeleteConfirm from '../modals/DeleteConfirm'
const Teamcard = (props) => {
    const [toggle, setToggle] = useState(false);
    const [toggle2, setToggle2] = useState(false);
    const [toggle3, setToggle3] = useState(false);
    const [toggle4, setToggle4] = useState(false);
    const [data, setData] = useState([])
    const [completed, setCompletedTasks] = useState([]);
    const [pending, setPendingTasks] = useState([]);
    const [delayed, setDelayedTasks] = useState([]);
    const [deleteddata, setdeleteddata] = useState(false)
    const [id, setid] = useState(null)
    const [entriesCreatedToday, setEntriesCreatedToday] = useState([]);
    const url = process.env.REACT_APP_URL
    const fetchdata = () => {
        axios.get(`${url}team/${props.id}/tracker`)
            .then(res => setData(res.data))
            .catch()
    }
    useEffect(() => {
        fetchdata();
    }, [])
    // Process data to count tasks
    // Filter tasks based on status
    const pendingTasks = data.filter(task => task.status_name === 'pending' || task.status_name === 'To Be Started').length;
    const delayedTasks = data.filter(task => task.status_name === 'delayed' || task.status_name === 'In Progress - Delayed').length;
    const completedTasks = data.filter(task => task.status_name === 'completed').length;
    const totalTasks = data.length;
    useEffect(() => {
        // Set filtered tasks to state variables
        setCompletedTasks(completedTasks);
        setPendingTasks(pendingTasks);
        setDelayedTasks(delayedTasks);
    }, [data]);
    const close = () => {
        setToggle(false)
    }
    const close2 = () => {
        setToggle2(false)
    }
    const close3 = () => {
        setToggle3(false)
    }
    const close4 = () => {
        setToggle4(false)
    }
    const closedelete = () => {

        setdeleteddata(false)
    }
    const handleDelete = () => {
        // Implement your delete logic here
        axios.delete(`${url}teams/${props.id}/`)
            .then(res => props.refresh())
            .catch()

    };
    useEffect(() => {
        // Filter entries created today
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
        const entriesToday = data.filter(task => task.Entry_created_on.startsWith(today));
        setEntriesCreatedToday(entriesToday);
    }, [data]);
    return (
        <>

            <div className="teamcard-parent glass">
                <button className='delete-btn' onClick={() => { setdeleteddata(true) }}><FaTrash /></button>
                <div className="teamcard-left">
                    <div className="task-status">
                        <span className='pending-row' onClick={() => setToggle2(true)}>Pending: {pendingTasks}</span>
                        <span className='delayed-row' onClick={() => setToggle3(true)}>Delayed: {delayedTasks}</span>
                        <span className='completed-row' onClick={() => setToggle(true)}>Completed: {completedTasks}</span>
                        <span onClick={() => setToggle4(true)}>New Enteries : {entriesCreatedToday.length} </span>
                    </div>
                    <Link className='team-name' to={props.url}>{(props.name.length < 15) ? (props.name.slice(0, 15)) : `${props.name.slice(0, 15)}...`}</Link>
                </div>
                <Link to={props.url} className="teamcard-right">
                    <span>{totalTasks}</span>
                    <p>entries</p>
                </Link>
            </div>

            {toggle && <TaskStatusModel fdata={data.filter(task => task.status_name === 'completed')} close={close} id="1" />}
            {toggle2 && <TaskStatusModel fdata={data.filter(task => task.status_name === 'pending' || task.status_name === 'To Be Started')} close2={close2} id="2" />}
            {toggle3 && <TaskStatusModel fdata={data.filter(task => task.status_name === 'delayed' || task.status_name === 'In Progress - Delayed')} close3={close3} id="3" />}
            {toggle4 && <TaskStatusModel fdata={entriesCreatedToday} close4={close4} id="4" />}
            {deleteddata && <DeleteConfirm onDelete={handleDelete} onClose={closedelete} />}
        </>
    )
}

export default Teamcard