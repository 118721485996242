import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import "../styles/allenteries.scss"
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";

// skeleton
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import DeleteConfirm from '../modals/DeleteConfirm';
import TeamDetailView from '../modals/TeamDetailView';
import EditTeamSheet from '../modals/EditTeamSheet';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
const SheetByTeam = () => {
    const [loading, setloading] = useState(false)
    const [detail, setdetail] = useState(false)
    const [edit, setedit] = useState(false)
    const [deletedata, setdeletedata] = useState(false)
    const [id, setid] = useState(null)
    const [data, setdata] = useState([])
    useEffect(() => {
        fetchData();
    }, [])
 // 
 const formatDatetime = (datetimeString) => {
    const datetime = new Date(datetimeString); // Convert the datetime string to a Date object
    const day = datetime.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if needed
    const month = (datetime.getMonth() + 1).toString().padStart(2, '0'); // Get the month (Note: month is zero-based) and pad with leading zero if needed
    const year = datetime.getFullYear(); // Get the year
  
    return `${day}/${month}/${year}`; // Return the formatted date string
  };
// 
    const url = process.env.REACT_APP_URL
    const getRowClassName = (params) => {
        const status = params.row.status;

        if (status === "completed") {
            return "completed-row";
          }
          if (status === ("delayed") || status === ("In Progress - Delayed"))  {
            return "delayed-row";
          }
          if (status === ("pending") || status === ("To Be Started")){
            return "pending-row";
          }

        return "";
    };
    // fetching
    const { id: teamid } = useParams()
    const fetchData = () => {
        axios.get(`${url}team/${teamid}/tracker/`)
            .then(res => { setloading(true); setdata(res.data) })
            .catch()
    }
    useEffect(() => {
        fetchData();
    }, [teamid])
    const columns = [
        // { field: 'serial_no', headerName: 'Serial no', width: 100 },
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'entry_Date', headerName: 'Entry Created On', width: 150 },
        { field: 'Client_name', headerName: 'Client Name', width: 130 },
        { field: 'Owner', headerName: 'Owner', width: 130 },
        { field: 'Entry_by', headerName: 'Entry Created By', width: 180 },
        { field: 'Start_Date', headerName: 'Start Date', width: 130 },
        {
            field: 'End_Date',
            headerName: 'End Date',
            width: 130,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
        },
        {
            field: 'Deliverable_brief',
            headerName: 'Deliverable Links',
            width: 180,
            renderCell: (params) => {
                // Check if the cell value is a valid URL
                const isLink = /^(ftp|http|https):\/\/[^ "]+$/.test(params.value);
            
                // If it's a link, render it as a clickable link; otherwise, render it as plain text
                return isLink ? (
                  <a href={params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                  </a>
                ) : (
                  <span>{params.value}</span>
                );
            },
        },
        {
            field: 'feedback',
            headerName: 'Deliverable Description',
            sortable: false,
            valueGetter: (params) => params.row.feedback,
            width: 200,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='handle-buttons'>
                    <button onClick={() => handleView(params.row.id)} className='action-btn'><FaEye /></button>
                    <button onClick={() => handleEdit(params.row.id)} className='action-btn'><MdEdit /></button>
                    <button onClick={() => { setdeletedata(true); setid(params.row.id) }} className='action-btn'><FaTrash /></button>
                </div>
            ),
        },
    ];
    const handleView = (pid) => {
        // Implement your edit logic here
        setid(pid)
        setdetail(true)
    };
    const handleEdit = (pid) => {
        // Implement your edit logic here
        setid(pid)
        setedit(true)
    };

    const handleDelete = () => {
        // Implement your delete logic here
        axios.delete(`${url}tracker/${id}`)
            .then(res => fetchData())
            .catch()
    };
    const close = () => {
        setdetail(false)
    }
    const closeedit = () => {
        setedit(false)
    }
    const reedit = () => {
        setedit(true)
        setdetail(false)
    }
    const closedelete = () => {

        setdeletedata(false)
    }
    const rows = data.reverse().map((data, index) => {
        return ({
            serial_no: index + 1, id: data.id, Client_name: data.client_name,Owner: data.sheet_owner_name ? data.sheet_owner_name : "N/A", Start_Date: data.start_date, End_Date: data.completion_date, status : data.status_name,Deliverable_brief:data.deliverable_brief ,feedback: data.deliverable_description ,Entry_by:data.Entry_by,entry_Date: formatDatetime(data.Entry_created_on),
        })
    });
    const navigate = useNavigate()
    const CustomToolbar = () => {
        return (
            <div className="button-custom" style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "0px 10px" }}>
                <Button style={{ width: "fit-content" }} variant="contained" color="primary" onClick={() => navigate(`/teammembers/${teamid}`)}>
                    View Team Members
                </Button>
            </div>
        );
    };
    // Process data to count tasks
    return (
        <>
            <div className='team-sheet'>
                {loading ? <DataGrid
                    getRowClassName={getRowClassName}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    disableRowSelectionOnClick={true}
                    hideFooterSelectedRowCount={true}
                    slots={{
                        toolbar: () => (
                            <React.Fragment>
                                <GridToolbar />
                                <CustomToolbar />
                            </React.Fragment>
                        ),
                    }}
                /> : <>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                        <Skeleton variant="rectangular" animation="wave" width={700} height={60} />
                    </Stack>
                </>}
            </div>

            {detail && <TeamDetailView close={close} reedit={reedit} id={id} />}
            {edit && <EditTeamSheet close={closeedit} id={id} refresh={fetchData}/>}
            {deletedata && <DeleteConfirm onDelete={handleDelete} onClose={closedelete} />}
        </>
    )
}

export default SheetByTeam