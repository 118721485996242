import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import Select from 'react-select';
import '../styles/addemployee.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Flip } from 'react-toastify';
const AddEmployee = (props) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [employee, setEmployee] = useState([]);
  const url = process.env.REACT_APP_URL
  const fetchEmployee = () => {
    axios.get(`${url}employee/`)
      .then(res => setEmployee(res.data))
      .catch();
  }

  useEffect(() => {
    fetchEmployee();
  }, []);
  const handleCreateTeam = (event) => {
    event.preventDefault();
    const teamMembers = selectedOption.map(option => option.value);
    const postData = {
      team_name: teamName,
      team_members: teamMembers
    };

    axios.post(`${url}teams/`, postData)
      .then(res => {
        toast.success('Team Created', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Flip,
        });
        props.close();
        props.refresh()

        // Add any necessary handling after team creation
      })
      .catch();
  };

  return (
    <div className="employee-add glass">
      <div className="form-container">
        <p><RxCross1 onClick={props.close} /></p>
        <form onSubmit={handleCreateTeam}>
          <input
            type="text"
            placeholder='Team Name'
            value={teamName}
            onChange={(event) => setTeamName(event.target.value)}
          />
          <Select
            isMulti
            value={selectedOption}
            onChange={setSelectedOption}
            options={employee.map(item => ({ value: item.id, label: item.employee_name }))}
            placeholder='Select Members'
            className='select'
          />
          <button type="submit" className='btn'>Create Team</button>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        limit={1}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition="Flip"
      />
    </div>
  );
};

export default AddEmployee;
